.FilesDragAndDrop {
  position: relative;
}

.FilesDragAndDrop__area {
  width: auto;
  justify-content: center;
  flex-flow: column nowrap;
  color: #555555;
  border: 2px dashed var(--header-bg);
  border-radius: 24px;
  min-height: 308px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 53px;
  font-weight: 400;
  font-size: 15px;
  font-family: "Jokker-semibold";
  gap:5px !important;
}

.FilesDragAndDrop__area .area__icon {
  font-size: 64px;
  margin-top: 20px;
}

.FilesDragAndDrop .FilesDragAndDrop__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  background-color: #e7e7e7;
  border-radius: 12px;
  color: #7f8e99;
  font-size: 24px;
  opacity: 0.9;
  text-align: center;
  line-height: 1.4;
}

.FilesDragAndDrop__placeholder--error {
  background-color: #f7e7e7;
  color: #cf8e99;
}

.FilesDragAndDrop__placeholder--success {
  background-color: #e7f7e7;
  color: #8ecf99;
}
