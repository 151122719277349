* {
  /* font-family: "Nunito", sans-serif; */
  font-family: "Jokker-Regular";
  letter-spacing: 0.25px !important;
}

.css-5aijib,
.drafts-card,
.card-active,
.purchased-card,
.closed-card,
.other-status-card,
.overview_insurance_card,
.id_box ,
.insurance-details,.previewSteps,.shadowbox,.insurance-header,.profile-input-section,.sticky_footer{
  box-shadow: 0px 6px 12px #00000029 !important;
}
.secondHeader {
  display: none;
}
@media (min-width: 576px) {
  .container_width {
    /* max-width: 540px !important; */
    padding: 0 40px;
  }
}
@media (min-width: 768px) {
  .container_width {
    /* max-width: 720px !important; */
    padding: 0 40px;
  }
}
@media (min-width: 992px) {
  .container_width {
    /* max-width: 880px !important; */
    padding: 0 40px;
  }
}
@media (min-width: 1024px) {
  .Insurance_Marketplace_card:hover {
    background-color: #091539;
    box-shadow: 0px 6px 12px #00000008;
  }
  .Insurance_Marketplace_card:hover h3,
  .Insurance_Marketplace_card:hover p {
    color: var(--white);
  }
}
@media (min-width: 1200px) {
  .container_width {
    /* max-width: 1040px !important; */
    padding: 0 40px;
  }
}
@media (min-width: 1300px) {
  .container_width {
    /* max-width: 1100px !important; */
    padding: 0 40px;
  }
}
@media (min-width: 1400px) {
  .container_width {
    /* max-width: 1240px !important; */
    padding: 0 40px;
  }
}
@media (min-width: 1800px) {
  .container_width {
    /* max-width: 1560px !important; */
      padding: 0 40px;
  }
  .partner_box p {
    min-height: 91px;
  }

  .hero_main {
    /* padding-top: 250px !important; */
  }
  .notFoundContain img {
    width: 73% !important;
    margin: 20px auto !important;
  }
}
@media (min-width: 1921px) {
  .container_width {
    max-width: 1560px !important;
      /* padding: 0 40px; */
  }
}
.header_container {
  max-width: 95% !important;
}
.layout {
  /* margin-top: 80px; */
  position: relative;
  background-color: var(--white);
  min-height: calc(100vh - 300px);
  padding-bottom: 30px;
}

.heroSection {
  background-color: var(--white);
  height: 689px;
  /* margin-top: 80px; */
}

.w-fit {
  width: -webkit-fill-available;
  width: -moz-available;
}
.bglight {
  background-color: var(--dashboard-bg) !important;
}
.preview-main-header-section {
  position: sticky;
  top: 83px;
  background-color: var(--white);
  z-index: 3;
  box-shadow: 0px 6px 12px #00000015;
}

.createAccount {
  background-color: var(--dashboard-bg);
  width: 900px;
  height: 600px;
  border-radius: 10px;
  margin: auto;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.mainHeadingModal {
  color: var(--white) !important;
  font-family: "jokker-bold" !important;
  font-size: 24px !important;
  max-width: 370px !important;
  display: block;
  margin: 20px auto;
  text-align: center;
}

.subHeading {
  color: var(--white) !important;
  font-family: "jokker-semibold" !important;
  font-size: 18px !important;
  max-width: 528px !important;
  display: block;
  margin: 20px auto;
  text-align: center;
}

.modalbtn {
  display: block;
  margin-left: auto;
  font-family: "jokker-semibold" !important;
  margin-right: auto;
  color: var(--black);
  background-color: var(--yellow);
  border-radius: 12px;
  border: none;
  width: max-content;
  height: 53px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.modalbtn[disabled]{
  opacity: 0.5 !important; 
}

.remove-icon {
  z-index: 1;
  top: -16px !important;
}
.remove-icon svg{
 height:25px !important;
 width:25px !important;
}
.heroInfo {
  display: flex;
  align-items: center;
  height: 689px;
}

.heroText {
  padding: 10px;
}

.heroText h2,
.heroText h5 {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
}

.preview-header-main {
  background-color: var(--white);
}

.heroText h5 {
  font-size: 16px;
  color: var(--ceramic-gray);
  margin-bottom: 20px;
}

.overview_card_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overview_card_heading h2 {
  margin: 0;
  padding: 0;
  color: var(--turquoise);
  font-family: 'bunch-bold';
}

.overview_card_number h1 {
  margin: 0;
  padding: 0;
  color: var(--yellow);
  font-size: 64px;
}

.overview_card {
  cursor: pointer;
  padding: 30px;
  background-color: var(--header-bg);
  box-shadow: 0px 6px 12px #00000008;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
/* .overview_card:hover{
  background-color: var(--turquoise);
} */
.overview_card:hover .overview_card_number h1,.overview_card:hover .overview_card_heading h2,.overview_card:hover .overview_card_icon{
  color: var(--white) !important;
}
.overview_card_icon {
  color: var(--turquoise);
}

.heroText h2 {
  /* color:  var(--yellow); */
  font-size: 30px;
  margin-bottom: 20px;
}

.slick-dots li {
  margin: 0 !important;
}

.overview_title {
  color: var(--header-bg);
  margin: 0;
  text-transform: capitalize;
  padding: 0;
  font-family: "jokker-bold";
  font-size: 24px;
}

.read-all-notification {
  color: var(--violet);
  line-height: 25px;
  font-size: 16px;
  font-family: "jokker-medium";
  margin: 0;
  cursor: pointer;
}

.overview_table_heading {
  color: var(--header-link) !important;
  font-size: 18px !important;
  font-family: "jokker-regular" !important;
}

.overview_table_text {
  color: var(--header-bg) !important;
  font-size: 18px !important;
  font-family: "jokker-medium" !important;
  text-transform: capitalize;
}

.overview_table_btn {
  cursor: pointer;
}

.view_button {
  background-color: var(--yellow) !important;
  padding: 8px 16px !important;
  border-radius: 8px !important;
  color: var(--header-bg) !important;
  font-size: 18px !important;
  font-family: "jokker-semibold" !important;
  text-transform: none !important;
}

.insurance_back-btn {
  background-color: transparent !important;
  border: 1px solid var(--header-bg) !important;
}

.preview_edit_btn {
  padding: 9px 32px !important;
  background: var(--utility-green) !important;
}

.cancel_button {
  background-color: transparent !important;
  border: 1px solid var(--white) !important;
  color: var(--white) !important;
}

.leander-user-btn {
  display: flex;
  justify-content: end;
  margin-top: 10px;
  gap: 20px;
}

.breadcrumb-main {
  padding: 13px 0px;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
}

.chat_btn {
  padding: 16px 32px !important;
  line-height: 25px !important;
}

.chat_btn_icon {
  display: none;
}

.flex_box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: " " !important;
  text-align: center;
  background: var(--ivory);
  opacity: 1 !important;
  color: var(--black);
}

.slick-dots li.slick-active button:before {
  height: 20px;
  width: 10px;
  background-color: var(--black);
  border-radius: 10px;
}

.heroSection .slick-dots {
  bottom: 100px !important;
  width: unset !important;
  margin: unset !important;
  list-style: unset !important;
  text-align: unset !important;
}

.heroText {
  color: var(--light-black);
}

.heroImage img {
  margin: auto;
  display: block;
}

.align-items-center {
  align-items: center;
}

.gap-10 {
  gap: 10px !important;
}

.image-section {
  background-color: var(--header-bg);
  height: 600px;
  position: relative;
  overflow: hidden;
}

.w-50 {
  width: 50% !important;
}

.logo-create {
  margin: auto;
  display: block;
  margin-top: 120px;
}

.illu-create {
  margin: auto;
  display: block;
  height: 413px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.illu{
  width: 301px;
}

.roundShapeNew {
  position: absolute;
  height: 470px;
  width: 470px;
  background-color: var(--yellow);
  opacity: 0.1;
  top: -80px;
  right: -10%;
  border-radius: 100%;
}

.create-heading h1 {
  color: var(--header-bg);
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.form-section {
  padding: 40px;
  width: 40%;
}
.css-1ab6zav-MuiPaper-root-MuiAccordion-root{
  box-shadow: unset !important;
}
.css-1ab6zav-MuiPaper-root-MuiAccordion-root:before{
  content: none !important;
}
.create-field-main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 40px 0;
}

.create-field {
  position: relative;
}

.input-img {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  right: 20px;
}

.date-birth {
  top: 50%;
}

.align-right {
  margin: unset;
  margin-left: auto !important;
}

.side-bar {
  width: 325px;
  height: -webkit-fill-available;
  height: -moz-available;
  background-color: var(--white);
  position: fixed;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 40px var(--carbon-black);
  background-color: var(--dashboard-bg);
}

.side-bar-top .active {
  box-shadow: 0px 0px 16px var(--carbon-black);
}

/* .mainSideBar .active {
  position: relative;
  background-color: var(--white);
  padding: 11px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
} */
/* .mainSideBar .active::before {
  content: " ";
  height: 100%;
  width: 3px;
  background-color: var(--yellow);
  position: absolute;
  left: 0;
  top: 0;
} */
.side-bar-link {
  padding: 20px 30px;
  font-size: 20px;
  position: relative;
  display: flex;
  gap: 25px;
  cursor: pointer;
}

.side-bar-link p {
  margin: 0;
}

.insurance_flex_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 30px;
}

.imageIcon p {
  max-width: 400px !important;
}

.imageIcon img {
  max-width: 250px !important;
  height: auto !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  right: 0 !important;
}

.side-bar-icon {
  height: 30px;
  width: 30px;
  background-color: var(--off-white);
}

.activity-dashboard {
  /* margin-left: 325px; */
  width: 100%;
  background-color: var(--dashboard-bg);
  /* height: 100vh; */
}

.overview_insurance_card_policy_inner p {
  margin: 0;
  color: var(--header-bg);
  font-size: 18px;
  font-family: "jokker-medium";
  text-transform: capitalize;
}

.overview_insurance_card_policy_inner_text {
  opacity: 50%;
  font-family: "jokker-semibold";
}

.verification-date {
  display: flex;
  flex-direction: column;
}

.text-end {
  text-align: end;
}

.overview_insurance_card_policy_inner_text_bold {
  font-family: "bunch-bold" !important;
  font-size: 20px !important;
}

.overview_insurance_card_download {
  border: 1px solid var(--header-bg) !important;
  border-radius: 8px !important;
  background-color: var(--white) !important;
  color: var(--header-bg);
  font-family: "jokker-medium" !important;
  font-size: 18px !important;
  width: -webkit-fill-available !important;
  width: -moz-available !important;
  text-transform: capitalize !important;
}

.overview_insurance_card_download:hover {
  background-color: var(--yellow) !important;
  border-color: var(--yellow) !important;
}

.overview_insurance_card_policy_inner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 25px 0;
}

.justify-between .MuiAccordionSummary-content{
  justify-content: space-between;
}
.justify-between .Mui-expanded img{
  transform: rotate(180deg);
}
.modal_bg .overview_insurance_card_policy_inner{
  margin: 10px 0;
}
.modal_bg .overview_insurance_card_policy_inner_text_bold{
  font-size: 16px !important;
}
.image_col_bg {
  background-color: var(--header-bg);
  padding: 40px;
  border-radius: 24px;
  position: relative;
  color: var(--white);
}

.image_col_bg h1 {
  font-size: 28px;
  font-family: "bunch-bold";
  color: var(--white);
  line-height: 34px;
}

.image_col_bg p {
  color: var(--white);
  font-size: 18px;
  margin: 20px 0;
  max-width: 851px;
  line-height: 25px;
}

.up_down {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--yellow);
  border-radius: 100px;
  color: var(--header-bg);
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
}

.step_img {
  position: absolute;
  height: 280px;
  bottom: 0;
  right: 30px;
}

.image_col_bg_step_text {
  font-family: "jokker-regular";
  font-size: 16px;
  max-width: 228px;
}

.titleWidth .image_col_bg_step_text {
  font-family: "jokker-regular";
  font-size: 16px;
  max-width: 258px !important;
}

.mb-18 {
  margin-bottom: 18px;
}

.banner-explore-btn {
  width: 172px;
  height: 53px;
  border-radius: 8px;
  margin: 0;
}

.banner-explore-btn:hover {
  background-color: var(--yellow);
  color: var(--black);
}

.mb-40 {
  margin-bottom: 40px;
}

.overview_insurance_card_policy {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 10px;
}

.overview_insurance_card {
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 6px 12px #00000008;
  padding: 20px;
  width: 100%;
}

.policySlider {
  width: -webkit-fill-available !important;
  width: -moz-available !important;
  margin-right: 20px;
}

.overview_insurance_card h3 {
  font-size: 18px;
  font-family: "jokker-bold";
  color: var(--header-bg);
  margin: 0;
  text-transform: capitalize;
}
.modal_bg .overview_insurance_card h3,.modal_bg .overview_insurance_card_policy_inner p{
  font-size: 16px;
}
.aboutText {
  align-self: center;
}

.main-dashboard {
  display: flex;
}

.aboutSection {
  padding: 75px 0;
  /* background-color:  var(--off-white); */
}

.footerLink {
  display: flex;
  gap: 20px;
  margin: auto;
  width: fit-content;
  margin-top: 40px;
  margin-bottom: 40px;
}

.footerLink h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: var(--white);
}

.footerLink h3:hover {
  color: var(--orange);
}

.footerLink .active {
  color: var(--orange);
}

.footerSocial {
  height: 30px;
  width: 30px;
}

.bottomFooter {
  background-color: var(--header-bg);
  padding: 20px 0;
  margin: 0;
  text-align: center;
  border-top: 1px solid var(--white);
}

.bottomFooter p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  color: var(--white);
}

.footer h5 {
  font-size: 16px;
  font-weight: 700;
  margin: 0 auto;
  max-width: 730px;
  padding: 0;
  color: var(--white);
}

.productImg {
  margin: auto;
}

.d-flex {
  display: flex;
}

.productHeading {
  padding: 50px 0;
  background-color: var(--white);
}

.productHeading h1,
.productHeading p {
  margin: 0;
  padding: 0;
}

.productHeading p {
  font-size: 20px;
}

.footer {
  padding: 40px;
  text-align: center;
  background-color: var(--header-bg);
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0;
}

.activity-dashboard-header h1 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.activity-dashboard-header {
  background-color: var(--white);
  padding-top: 44px;
  padding-left: 24px;
  padding-right: 24px;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}
.notification-menu{
  position: relative;
}
.notification-red-dot{
  position: absolute;
  top: -2px;
  right: 1px;
  height: 12px;
  width: 12px;
  background-color: #f06363;
  border-radius: 50%;
}
.gap-20 {
  gap: 20px;
}
.p-20{
  padding: 20px !important;
}
.search-section input {
  height: 40px;
  width: 325px;
  padding-left: 40px;
  border: none;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.search-section input:focus {
  outline: unset;
}

.align-items-center {
  align-items: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.self-center {
  align-self: center;
}

.relative {
  position: relative;
}

.relative img {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.grid-box {
  height: 40px;
  width: 40px;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.grid-icon {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: auto;
  margin-top: 50%;
  width: fit-content;
  transform: translateY(-50%);
}

.icon-line1 {
  height: 3px;
  width: 24px;
  border-radius: 4px;
  border: 2px solid var(--light-black);
}

.icon-line2 {
  height: 3px;
  width: 13px;
  border-radius: 4px;
  background-color: var(--yellow);
  border: 2px solid var(--light-black);
}

.mt-44 {
  margin-top: 44px;
}

.gap-30 {
  gap: 30px;
}

.dashboard-card {
  width: 40%;
  border: 1px solid var(--cream);
  border-radius: 10px;
  height: 100px;
  padding: 24px;
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 20px;
  background-color: var(--marble-white);
  cursor: pointer;
}

.card-main-dashboard {
  /* width: 100%; */
  flex-wrap: wrap;
}
.verify-model-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.verify-model-header p {
  font-size: 24px;
  font-family: "jokker-bold";
  line-height: 24px;
  padding: 0;
  margin: 0;
}
.verify-insurance-content:first-child, .verify-insurance-policy:first-child{
  border-bottom: 2px solid #FFFFFF33;
  padding-bottom: 30px;
}
.verify-insurance-content, .verify-insurance-policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.pt-30{
  padding-top: 30px !important;
}
.verify-insurance-content p, .verify-insurance-policy p {
  font-size: 18px;
  line-height: 25.2px;
  color: var(--white);
  text-align: center;
  margin-top: 0px;
  font-family: "jokker-semibold";
}
.verify-insurance-content .view_button {
  /* background-color: var(--turquoise) !important;
  width: max-content !important; */
}
.verify-teams p{
  font-size: 18px;
  line-height: 25.2px;
  color: var(--white);
  font-family: "jokker-medium";
  margin: 0;
  padding: 0;
}
.verify-teams p:first-child {
  margin-bottom: 30px;
}

.verify-teams-radio {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1.5px solid var(--turquoise);
}
.verify-insurance-policy .view_button {
  width: max-content !important;
}
.dashboard-card::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: var(--bottle-green);
}

.font-36 {
  font-size: 36px;
}

.icon-bg {
  width: 56px;
  height: 56px;
  background-color: var(--cape-white);
  border-radius: 100%;
}

.dashboard-card-text {
  font-size: 16px;
  color: var(--black);
  font-weight: 600;
  align-self: center;
}

.orange {
  background-color: var(--cream);
}

.orange h1 {
  color: var(--black);
}

.orange::before {
  background-color: var(--khamam-gray);
}

.yellow h1 {
  color: var(--danger-red);
}

.yellow::before {
  background-color: var(--danger-red);
}

.yellow {
  background-color: var(--carme-white);
}

.red h1 {
  color: var(--yellow);
}

.red::before {
  background-color: var(--light-yellow);
}

.red {
  background-color: var(--peach);
}

.side-bar-top .active::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: var(--orange);
}

.grid-box {
  display: none;
}

/* .mainSideBar a,
.mainSideBar p.link {
  padding: 11px;
  margin: 0;
} */
.gap-50 {
  gap: 50px;
}

.card-active {
  /* width: 295px; */
  border-radius: 10px;
  /* position: relative; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* cursor: pointer; */
  background-color: var(--white) !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.tag {
  /* position: absolute;
  top: 10px;
  right: 10px; */
  color: var(--bottle-green);
  font-size: 16px;
  font-family: "jokker-medium";
}

.tag::first-letter {
  text-transform: uppercase;
}

.icon-bg-active-card {
  height: 60px;
  /* width: 60px; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border-radius: 15px;
  background-color: var(--white) !important;
}

.card-active h1,
.card-active p {
  margin: 0;
  padding: 0;
}

.card-active h1 {
  font-size: 18px;
  font-family: "jokker-bold" !important;
}

.card-active p {
  color: var(--concrete-gray);
  font-size: 16px;
  margin-top: 10px !important;
}

.drafts-card .tag {
  color: var(--khamam-gray);
  background-color: var(--violet);
}

.other-status-card .tag {
  color: var(--header-bg);
}

.purchased-card .tag {
  color: var(--header-bg);
  padding: 8px 16px;
  border-radius: 5px;
  background-color: var(--utility-green);
}

.closed-card .tag {
  color: var(--header-bg);
  padding: 8px 16px;
  border-radius: 5px;
  background-color: var(--utility-red);
}

.active-card {
  background-color: var(--white);
}

.drafts-card {
  /* filter: hue-rotate(45deg); */
  background-color: var(--white) !important;
}

.purchased-card {
  background-color: var(--peach);
}

.closed-card {
  background-color: var(--venial);
}

.other-status-card {
  background-color: var(--cream);
}

.active-card .icon-bg-active-card {
  background-color: var(--chat-bg);
}

.active-card .tag {
  background-color: var(--utility-green);
  padding: 8px 16px;
  border-radius: 5px;
  color: var(--header-bg);
}

.drafts-card .tag {
  /* background-color: var(--header-bg); */
  padding: 8px 16px;
  border-radius: 5px;
  color: var(--white);
}

.drafts-card .icon-bg-active-card {
  background-color: var(--chat-bg);
}

.purchased-card .icon-bg-active-card {
  background-color: var(--chat-bg);
}

.closed-card .icon-bg-active-card {
  background-color: var(--chat-bg);
}

.active-lead-btn {
  display: flex;
  gap: 10px;
  padding-top: 20px;
  border-top: #e6e7eb solid 2px;
}

.quote-request {
  width: -webkit-fill-available !important;
  height: 53px;
  background-color: var(--header-bg) !important;
  color: var(--white) !important;
}

.other-status-card .icon-bg-active-card {
  background-color: var(--ponds-white);
}

.flex-wrap {
  flex-wrap: wrap;
}

.setting-img {
  margin: auto;
  display: block;
}

.setting-text {
  margin-top: 80px;
  text-align: center;
  font-size: 28px;
}

.setting-text span {
  font-weight: 400;
}

.agent-chat {
  height: calc(100vh - 250px);
  display: block;
  margin-top: 39px !important;
}

.support-chat {
  height: calc(100vh - 250px);
  display: block;
}

.chat-header {
  padding: 16px 40px;
  display: flex;
  align-items: center;
  box-shadow: 0px 6px 12px #00000008;
  border-bottom: 2px solid #ebecef;
}

.help-chat-header {
  background-color: var(--white);
  height: 88px;
  display: flex;
  align-items: center;
}

.bg_white {
  background-color: var(--white);
}

.user-logo {
  position: relative;
}

.online-status {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #54c192;
  border: 1px solid #fff;
}

.user_support_name {
  font-size: 24px;
  color: var(--header-bg);
  font-family: "jokker-bold";
}

.online-status.idle,
.online-status.unknown {
  background-color: var(--charcoal-black);
}

.online-status.assigned {
  background-color: var(--lantern-green);
}

.online-status.waiting {
  background-color: var(--saffron);
}

.online-status-text {
  text-transform: capitalize;
  color: #54c192;
  font-size: 14px;
}

.online-status-text.assigned {
  color: var(--lantern-green);
}

.online-status-text.waiting {
  color: var(--saffron);
}

.chat-icon-info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.green-span {
  color: #54c192;
}

.chat-icon-info h4 {
  padding: 0;
  margin: 0;
  font-family: "jokker-bold";
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
}

.chat-header .userImg {
  height: 48px;
  width: 48px;
  border-radius: 100%;
  background-color: var(--turquoise);
  text-transform: uppercase;
  font-size: 24px;
  line-height: 45px;
  font-family: "jokker-bold";
  display: flex;
  justify-content: center;
  align-items: center;
}

.help-chat-header .userImg {
  background-color: unset;
}

.chat-body {
  /* background-color: var(--dashboard-bg); */
  /* height: 100%; */
  padding: 0px 40px;
  position: relative;
}

.help-chat-body {
  background-color: var(--dashboard-bg);
  /* height: 100%; */
  position: relative;
}

.chat-body-inner {
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
  /*justify-content: flex-end;*/
  /* height: 100%; */
  height: calc(100vh - 407px);
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  overflow-y: auto;
  /* padding: 0px 30px; */
  /* border-right: 1px solid var(--mystick-white); */
}

.help-chat-body-inner {
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
  height: calc(100vh - 272px);
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  overflow-y: auto;
}

/* .chat-body-inner::before,
.chat-body-inner::after {
  content: "";
  transition: all ease 0.3s;
}

.chat-body-inner.shadowTop::before {
  box-shadow: inset 0px 75px 35px -65px rgba(0, 0, 0, 0.42);
  width: 100%;
  height: 50px;
  overflow: hidden;
  display: flex;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.chat-body-inner.shadowBottom::after {
  box-shadow: inset 0px -75px 35px -65px rgba(0, 0, 0, 0.42);
  width: 100%;
  height: 50px;
  overflow: hidden;
  display: flex;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
} */

.no-more-messages {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px auto;
  text-align: center;
  display: block;
  width: 100%;
  color: var(--stone-gray);
}

.please-wait-for-messages {
  position: absolute;
  top: 45%;
  left: 0;
  margin: 10px auto;
  text-align: center;
  display: block;
  font-size: 30px;
  width: 100%;
  color: var(--stone-gray);
  opacity: 0;
  animation: fadeInOut 1.5s infinite;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.no-quotes-found-chat {
  display: flex;
  justify-content: center;
  font-weight: bold;
}

/* .chat-body-inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.chat-body-inner::-webkit-scrollbar {
  width: 10px;
} */

/* .chat-body-inner::-webkit-scrollbar-thumb { */
/* background-color: var(--yellow); */
/*border: 2px solid #555555;*/
/* } */

.sender {
  display: flex;
  gap: 10px;
  align-items: center;
}

.sender p {
  font-size: 18px;
  padding: 0;
  margin: 5px 0 0;
  font-family: "jokker-regular";
  color: #4b5764;
  opacity: 0.5;
}

.sender-message {
  background-color: var(--violet);
  padding: 20px 40px;
  border-radius: 35px 35px 35px 0px;
  font-size: 18px;
  font-family: "jokker-regular";
  color: var(--white);
  width: fit-content;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sender-file-name {
  text-align: center;
  font-size: 16px;
  font-family: "jokker-medium";
}

.receiver {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: auto;
}

.receiver p {
  font-size: 18px;
  text-align: end;
  padding: 0;
  margin: 5px 0 0;
  font-family: "jokker-regular";
  color: #4b5764;
  opacity: 0.5;
}

.receiver-message {
  word-break: break-all;
  background-color: var(--turquoise);
  padding: 20px 40px;
  border-radius: 35px 35px 0px 35px;
  color: var(--header-bg);
  font-size: 18px;
  font-family: "jokker-regular";
  width: fit-content;
  margin-left: auto;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.receiver-file-name {
  text-align: center;
  font-size: 16px;
  font-family: "jokker-medium";
}

.receiver-message img.image,
.sender-message img.image {
  width: 150px;
  height: auto;
}

.receiver-message img.document,
.sender-message img.document {
  width: 100px;
  height: 100px;
}

.chat-type {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 20px 40px !important;
  box-shadow: 0px 6px 12px #00000008;
  border-top: 2px solid #ebecef;
}

.help-chat-type {
  height: 80px;
  display: flex;
  gap: 20px;
  align-items: center;
  background: white;
}

.help-chat-type input,
.help-chat-type .attachement-preview {
  position: relative;
  height: 50px;
  width: 100%;
  padding: 5px 10px;
  background-color: transparent;
  border: unset;
}

.chat-type input {
  position: relative;
  width: 100%;
  padding: 18px 30px;
  color: rgba(75, 87, 100, 0.2);
  border-radius: 28px;
  background-color: rgba(75, 87, 100, 0.2);
  border: unset;
}

.chat-type .attachement-preview {
  position: relative;
}

.help-chat-type .attachement-preview {
  position: relative;
}

.chat-type .attachement-preview {
  display: flex;
  justify-content: flex-end;
}

.help-chat-type .attachement-preview {
  display: flex;
  justify-content: flex-end;
}

.chat-type .attachement-preview .attachment {
  align-items: center;
  flex-direction: row-reverse;
  width: 50px;
  height: 50px;
  border: 3px solid var(--yellow);
}

.help-chat-type .attachement-preview .attachment {
  align-items: center;
  flex-direction: row-reverse;
  width: 50px;
  height: 50px;
  border: 3px solid var(--yellow);
}

.help-chat-type .attachement-preview .removeAttachment {
  position: absolute;
  top: -5px;
  right: 0;
  cursor: pointer;
  flex: 1;
}

.chat-type .attachement-preview .removeAttachment {
  position: absolute;
  top: -5px;
  right: 0;
  cursor: pointer;
  flex: 1;
}

.chat-type .attachement-preview .removeAttachment:hover .removeAttachment-icon {
  top: -5px;
  width: 30px;
  height: 30px;
}

.help-chat-type
  .attachement-preview
  .removeAttachment:hover
  .removeAttachment-icon {
  top: -5px;
  width: 30px;
  height: 30px;
}

.chat-type .attachement-preview .removeAttachment .removeAttachment-icon {
  width: 20px;
  height: 20px;
  transition: all ease 0.2s;
}

.help-chat-type .attachement-preview .removeAttachment .removeAttachment-icon {
  width: 20px;
  height: 20px;
  transition: all ease 0.2s;
}

.help-chat-type input:focus {
  outline: none;
}

.chat-type input:focus {
  outline: none;
}

.chat-type input::placeholder {
  color: var(--black);
  font-size: 18px;
  font-weight: 500;
}

.help-chat-type input::placeholder {
  color: var(--black);
  font-size: 18px;
  font-weight: 500;
}

.chat-type input {
  color: var(--black);
  font-size: 18px;
  font-weight: 500;
}

.help-chat-type input {
  color: var(--black);
  font-size: 18px;
  font-weight: 500;
}

.send-warning {
  width: 100%;
  flex: 20 0 80%;
  font-size: 16px;
  font-weight: bold;
  color: var(--silver);
  cursor: not-allowed;
}

.imgArea {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.imgArea[data-disabled="true"] {
  cursor: not-allowed;
}

.imgArea img {
  width: 24px;
  height: 24px;
}

.send-btn {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: var(--yellow);
  cursor: pointer;
}

.send-btn.disabled {
  /* background-color: grey; */
  cursor: not-allowed;
}

.send-btn.active:hover {
  background: var(--selective-yellow);
}

.bottom-icon {
  position: absolute;
  bottom: 20px;
  right: 50%;
  border-radius: 30px;
  z-index: 10;
  cursor: pointer;
}

.d-block {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.home-image {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: var(--off-white);
}

.preview-button {
  padding: 9px 32px !important;
}

.preview-header-left {
  display: flex;
  /* gap: 10px; */
  align-items: center;
  width: -webkit-fill-available;
  width: -moz-available;
}

.fixed-edit-footer {
  background-color: var(--white);
  padding: 10px 0px;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -10px 20px #0000000d;
  border-radius: 8px 8px 0px 0px;
  text-align: end;
  z-index: 1;
}

.preview-edit-button {
  background-color: transparent !important;
  color: var(--header-bg) !important;
  border: 1px solid var(--header-bg) !important;
  width: 97px;
}

.preview-header-right {
  display: flex;
  gap: 0px;
  background-color: #09143a33;
  border: 1px solid #09143a80;
  border-radius: 8px;
  padding: 4px;
}

.preview-header-right button {
  background-color: transparent !important;
  width: 150px !important;
}

.chat-header-right button {
  font-size: 18px !important;
  line-height: 20px !important;
  font-family: "jokker-semibold" !important;
  padding: 8px 16px !important;
  background-color: transparent !important;
  width: max-content !important;
}

.chat-btn-divider {
  border-left: 1px solid #09143a80;
  margin: 10px;
}

.preview-header-right button.active {
  background-color: var(--header-bg) !important;
  color: var(--white) !important;
}

.preview-header-right .policy-tabs {
  width: fit-content !important;
  border-radius: 8px;
}

.preview-header-right .Mui-selected {
  background-color: var(--header-bg) !important;
  color: var(--white) !important;
  border: unset !important;
}

.preview-header-right .MuiTabs-indicator {
  background-color: transparent !important;
}

.breadcrumb-item {
  font-size: 18px;
  font-family: "jokker-medium";
  line-height: 19px;
  color: var(--black);
  /* opacity: 0.5; */
}

.breadcrumb-active {
  font-family: "jokker-Semibold";
  color: var(--black) !important;
  opacity: 1;
}

.quote-loader-main {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.quote-loader-text {
  color: var(--white);
  font-size: 28px;
  font-weight: 34px;
  font-family: "bunch-bold" !important;
}

.preview-header {
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.home-text h1,
.home-text p {
  margin: 0;
  padding: 0;
}

.home-text p {
  color: var(--black-current);
  font-size: 18px;
}

.home-text h1 {
  color: var(--header-bg);
  font-size: 24px;
  font-family: "jokker-bold" !important;
  text-transform: capitalize;
}

.home-text h1 span {
  color: var(--black);
}

.preview-tab {
  width: 250px;
  height: 44px;
  /* background-color: var(--cape-white); */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  opacity: 30%;
  border-bottom: 2px solid var(--header-bg);
}

.quotes-tab {
  width: 250px;
  height: 44px;
  /* background-color: var(--cape-white); */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.quotesTabActivated {
  opacity: 100%;
}

.edit-button {
  padding: 10px 40px;
  border-radius: 5px;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.edit-button-icon {
  height: 40px;
  width: 40px;
  background-color: #ffcd00;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.edit-button-icon svg {
  height: 20px;
  width: 20px;
}

.common-shadow {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 40px 40px;
  margin-top: 40px;
  background-color: var(--header-bg);
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.paddingmodal{
  padding-left: 24px;
  padding-right: 24px;
}
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.insurance-heading {
  font-weight: 700;
  position: relative;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--turquoise);
  margin-bottom: 40px;
}

.insurance-heading h1 {
  font-size: 24px;
  font-family: "jokker-bold" !important;
  color: var(--turquoise);
  margin: 0px;
}

.insurance-info-main {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0 0 10px;
  margin-bottom: 10px;
  /* border: 1px solid #e6e7eb; */
  width: 100%;
}
.insurance-text {
  padding: 16.5px 14px;
  border-radius: 4px;
  border: 1px solid #e6e7eb;
  border-color: rgba(0, 0, 0, 0.23);
}

/* .conditional-multi-entry-card {
  border: 2px solid #e6e7eb;
  border-radius: 8px;
  padding: 20px;
} */

.border-bottom-remove {
  border-bottom: unset !important;
}

.chack-insurance-label {
  font-size: 14px;
  max-width: 300px;
  color: var(--dove-gray);
}

.mt-70 {
  margin-top: 70px;
}

.auto-fill-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  border-bottom: 2px solid #222c4e;
  padding-bottom: 10px;
}
.flex_direction_column {
  flex-direction: column !important;
}
.insurance-form-label {
  margin-bottom: 10px;
}
.insurance-form-label span{
  color: red;
}
.auto-field-question {
  text-align: left;
  font-size: 24px;
  line-height: 23px;
  font-family: "jokker-semibold" !important;
  letter-spacing: 0px;
  color: #09143a;
  margin: 0;
}

.chack-insurance-text {
  font-size: 14px;
  color: var(--black);
  font-weight: 700;
}

.chack-insurance-info-main {
  display: flex;
  gap: 10px;
  row-gap: 5px;
  margin-left: 10px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.chack-insurance-info-main:last-child {
  margin-bottom: unset;
}

.insurance-label {
  /* max-width: 210px;
  min-width: 210px; */
  white-space: wrap;
  color: var(--black-current);
  font-size: 16px;
}

.loan-details-label {
  color: var(--white) !important;
  white-space: wrap;
  color: var(--black-current);
  font-size: 18px;
}

.insurance-text,
.insurance-text span {
  /* max-width: 256px; */
  /* min-width: 250px; */
  width: auto;
  white-space: wrap;
  color: var(--black-current);
  font-size: 16px;
  font-family: "jokker-semibold";
  display: flex;
  /* align-self: flex-end; */
  flex-direction: column;
}

.insurance-ul-list {
  /* max-width: 250px;
  min-width: 250px; */
  width: auto;
  white-space: wrap;
  color: var(--black-current);
  font-size: 18px;
  font-family: "jokker-semibold";
  list-style-type: square;
}
.tooltip-list-circle {
  list-style-type: circle;
}
.notification-read:hover {
  background-color: var(--white) !important;
}
.tooltip-list{
  padding-left: 20px !important;
}
.insurance-text.email {
  word-wrap: break-word;
}

.align-self-start {
  align-self: self-start !important;
}

.auto-fill-card {
  border-radius: 24px;
  /* background-color: var(--white); */
  /* padding: 29px 110px; */
}

.lender-user-question {
  border: 1px solid #48506c;
  padding: 40px;
  margin-top: 74px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-top-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
}

.font-family-regular {
  font-family: "jokker-regular" !important;
}

.lender-user-offer {
  position: absolute;
  background-color: var(--yellow);
  color: var(--header-bg);
  font-family: "jokker-semibold";
  font-size: 22px;
  border-top-left-radius: 8px;
  top: -36px;
  left: 0px;
  padding: 5px;
  border-top-right-radius: 8px;
}

.leander-user-title {
  padding-bottom: 20px;
  font-size: 24px;
  font-family: "Jokker-bold";
  color: var(--turquoise);
  border-bottom: 2px solid var(--turquoise);
}

.insurance-subheading {
  font-size: 20px;
  line-height: 23px;
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 30px;
  font-family: "Jokker-Bold";
  border-bottom: 2px solid #e6e7eb;
}

.previewSteps {
  max-height: calc(100vh - 385px);
  /* overflow-y: scroll; */
  border-radius: 8px;
  background-color: var(--white);
  padding: 20px;
  display: flex;
  /* flex-direction: column; */
  gap: 20px;
  position: sticky;
  top: 334px;
  margin-top: 20px;
}

.lead-Steps {
  max-height: calc(100vh - 250px);
  /* overflow-y: auto; */
  border-radius: 12px;
  background-color: var(--white);
  padding: 20px;
  display: flex;
  /* flex-direction: column; */
  gap: 20px;
  position: sticky;
  top: 200px;
  box-shadow: 0px 6px 12px #00000029;
  margin-top: 20px;
  /* position: relative; */
  overflow: hidden;
}
.progressLine {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.active-card-question {
  /* border: 1px solid var(--header-bg) !important; */
  box-shadow: 0px 6px 12px #00000029;
}
input.Mui-disabled[disabled] {
  -webkit-text-fill-color: #09143a !important;
}
.MuiSelect-select.Mui-disabled {
  -webkit-text-fill-color: #09143a !important;
  color: #09143a !important;
}
.MuiPopover-paper {
  max-height: 300px !important;
}
.mt-1 {
  margin-top: 3px;
}

.preview-count {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: var(--header-bg);
  font-size: 18px;
  font-family: "jokker-bold";
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--header-bg);
  opacity: 0.7;
}

.preview-main-header {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
  padding: 10px 10px;
  align-items: center;
  cursor: pointer;
  background-color: var(--white);
}

.active-step {
  background-color: var(--white);
  border-radius: 8px;
  position: relative;
}
.active-step::before {
  content: "";
  position: absolute;
  height: 4px;
  width: 100%;
  background-color: var(--header-bg);
  border-radius: 5px;
  left: 0;
  bottom: -20px;
  z-index: -1;
}
.active-step .preview-count {
  background: var(--header-bg);
  border: 1px solid var(--header-bg);
  color: white;
  opacity: 1;
}

.active-step h1 {
  color: var(--header-bg) !important;
  font-family: "jokker-semibold" !important;
}

.complete-step h1 {
  color: var(--header-bg) !important;
  font-family: "jokker-semibold" !important;
}
.complete-step svg path {
  stroke: var(--header-bg);
}
.complete-step svg rect {
  fill: var(--turquoise);
}

.active-sub-header p {
  color: var(--yellow) !important;
  font-family: "jokker-semibold" !important;
}

.preview-sub-header {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
  padding: 20px 0px 19px 80px;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.preview-sub-header::before {
  content: "";
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  width: 40px;
  background-color: var(--white);
}

.preview-stepper-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  z-index: 1;
  justify-content: center;
}
.complete-step h1 {
  opacity: 1 !important;
}
.preview-stepper-main::before {
  content: "";
  height: calc(100% - 30px);
  width: 2px;
  background-color: var(--white);
  position: absolute;
  top: 0;
  left: 39px;
  z-index: -1;
}

/* .preview-scroll {
  height: calc(100vh - 250px);
  overflow-y: scroll;
  width: auto;
} */

.preview-sub-header p {
  padding: 0;
  margin: 0;
  color: var(--white);
  font-size: 18px;
  line-height: 22px;
}

.preview-main-header h1 {
  padding: 0;
  margin: 0;
  font-family: "jokker-medium" !important;
  color: var(--header-bg);
  font-size: 18px;
  line-height: 22px;
  opacity: 0.7;
}
.active-step h1 {
  opacity: 1;
}
.border {
  border: 1px solid var(--white);
  border-radius: 8px;
  background-color: var(--white);
}

.py-20 {
  padding-top: 20px;
  /* padding-bottom: 20px; */
}
.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.mt-20 {
  margin-top: 20px !important;
}

.next-button {
  margin: unset;
}

.mb-44 {
  margin-bottom: 44px;
}

.pb-44 {
  padding-bottom: 44px;
}

.insurance {
  padding: 80px 0;
  margin-top: 80px;
}

.insuranceInfo h4 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.insuranceInfo p {
  margin-bottom: 0;
  padding: 0;
  margin-top: 0;
  font-size: 16px;
  line-height: 25px;
}

.insuranceInfo h6 {
  font-size: 18px;
  font-weight: 500;
  text-align: end;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  padding: 0;
}

.insuranceInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 20px;
}

.main-step {
  display: flex;
  gap: 20px;
  position: relative;
  justify-content: space-around;
}

.applicant-stap {
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 2;
  padding-left: 5px;
  padding-right: 5px;
  align-items: center;
  width: 150px;
}

.step_line {
  position: absolute;
  top: 25%;
  transform: translateY(-50%);
  width: 76%;
  height: 2px;
  right: 0;
  left: 0;
  margin: auto;
  background-color: var(--white);
}

.stap-count {
  height: 36px;
  width: 36px;
  border-radius: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.staper-active .stap-count {
  background-color: var(--turquoise);
  color: var(--header-bg);
}

.staper-active p {
  color: var(--turquoise) !important;
}

.inactive .stap-count {
  background-color: var(--header-bg);
  color: var(--white);
  border: 1px solid var(--white);
}

.header-avatar .userImg {
  height: 30px;
  width: 30px;
}

.complete .stap-count {
  background-color: var(--utility-green);
  color: var(--white);
}

.complete svg {
  height: 36px;
  width: 36px;
  border-radius: 100px;
}

.complete p {
  color: var(--utility-green);
}

.applicant-stap p {
  margin: 0;
  padding: 0;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 700;
  max-width: 150px;
  color: var(--white);
  text-wrap: wrap;
  text-align: center;
}

.inactive p {
  color: var(--white);
}

/* .inactive {
  opacity: 50%;
} */

/* .main-step:before {
  position: absolute;
  height: 3px;
  width: 100%;
  top: 0;
  left: 0;
  top: 50%;
  content: "";
  z-index: 1;
  background-color: var(--alto);
} */

.new_staper_bg {
  background-color: var(--header-bg);
}

.applicant-info-main {
  /* padding: 20px; */
  /* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1); */
  margin-bottom: 40px;
  border-bottom: 1px solid var(--header-link);
  padding: 41px 0px;
  /* border-radius: 10px; */
  /* background-color: var(--white); */
  overflow-y: auto;
}

.insuranceMain img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.customer-information-out-line {
  margin-top: 3px;
  /* padding: 20px 40px; */
  /* border: 1px solid var(--mercury-white); */
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  background-color: var(--white);
  /* transition: 0.5s; */
}
.card-title-content{
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  /* justify-content: space-between; */
  /* align-items: baseline; */
  padding:24px 24px 24px 24px !important;
  cursor:pointer;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background-color: var(--turquoise);
                        
}
.card-title-content p {
font-size: 14px;
}
.insurance-form-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.customer-information {
  /* margin-top: 40px; */
  padding-bottom: 40px;
}

.full-rounded {
  border-radius: 100px;
}

.customer-information-heading {
  position: relative;
  margin: 0;
  padding: 0;
  color: var(--header-bg);
  font-family: "jokker-bold";
  font-size: 24px;
  opacity: 1;
}

.customer-information-heading h1 {
  margin: 0;
  padding: 0;
  font-family: "jokker-bold" !important;
  font-size: 24px;
}

/* .customer-information-heading::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 8px;
  width: 60px;
  background-color: var(--yellow);
} */
.customer-information-input {
  width: 32.1%;
  position: relative;
  height: inherit;
}
.multiEntryCard .customer-information-input{
  width: 32% !important;
}
/* .insurance-form-section .customer-information-input .customer-information-input .main-form-field.d-flex.flex_direction_column,.insurance-form-section .customer-information-input .customer-information-input .main-form-field.d-flex.flex_direction_column .MuiFormControl-root{
  height: 100%;
} */
.insurance-form-section
  .customer-information-input
  .customer-information-input
  .main-form-field.d-flex.flex_direction_column
  .MuiFormControl-root {
  justify-content: space-between;
}

.customer-information-dropdown {
  width: 100%;
  position: relative;
}

.start-button {
  margin-left: auto;
  margin-right: unset;
  font-size: 18px;
  /* padding: 20px 40px; */
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.previous-button {
  margin-right: auto;
  margin-left: unset;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  /* padding: 20px 40px; */
  height: 64px;
  display: flex;
  gap: 10px;
  color: var(--white);
  transition: 0.5s;
}

.start-button span {
  min-width: 75px;
}

.previous-button span {
  min-width: 75px;
}
.secondpopup 
.next-img {
  display: none;
  margin-left: 10px;
}

.start-button:hover .next-img {
  display: block;
}

.previous-button:hover .next-img {
  display: block;
  transform: rotate(180deg);
}

.loginPopup {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  backdrop-filter: blur(5px);
  display: flex;
  gap: 80px;
  align-items: center;
  justify-content: center;
}

.fach-data:hover {
  border: 2px solid var(--yellow);
}

.fach-data-design {
  width: 172px;
  height: 172px;
  background-color: var(--dairy-cream);
  border-radius: 100%;
  position: absolute;
  top: -30%;
  left: -30%;
  transition: 0.9s;
}

.fach-data:hover .fach-data-design {
  left: 60% !important;
  top: -50% !important;
}

.fach-data h6,
.fach-data img {
  z-index: 99;
  position: relative;
}

.fach-data h6 {
  font-size: 18px;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  flex-direction: column;
  font-weight: 600;
}

.fach-data {
  /* height: 180px; */
  width: 240px;
  border: 2px solid transparent;
  background-color: var(--white);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  text-align: center;
  padding: 20px;
}

.quat-heading {
  font-family: "jokker-bold";
  font-size: 24px;
  color: var(--black);
  text-transform: capitalize;
}

/* .quat-heading::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 2px;
  width: 40px;
  background-color: var(--orange);
} */

.quat-heading-icon {
  height: 30px;
  width: 30px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navigation-icon {
  height: 30px;
  background-color: var(--pink-lady);
  border-radius: 10px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset !important;
}

.gap-50 {
  gap: 50;
}

.quote-card {
  max-width: 400px;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 6px 12px #00000008;
  padding: 20px;
  /* min-width: 220px; */
  margin-right: 20px;
  position: relative;
}

.quote_slider_inner {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quote_slider_title {
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
  gap: 10px;
  margin-top: 19px;
}

.quote_slider_inner p {
  margin: 0;
  color: var(--header-bg);
  font-size: 18px;
  line-height: 22px;
  font-family: "jokker-medium";
}

.quote_btn {
  height: 56px !important;
  border: 1px solid var(--header-bg) !important;
  border-radius: 8px !important;
  background-color: var(--white) !important;
  color: var(--header-bg);
  text-transform: capitalize !important;
  font-family: "jokker-medium" !important;
  font-size: 18px !important;
  width: -webkit-fill-available !important;
  width: -moz-available !important;
}

.lender_user_cancel_btn {
  /* border: 1px solid var(--header-bg) !important;
  background-color: var(--header-bg) !important;
  color: var(--white) !important; */
}

.quote_btn_accept {
  background-color: var(--header-bg) !important;
  color: var(--white) !important;
}

.quote_btn_accept[disabled] {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.existingInsuranceLoginBtn[disabled] {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.quote_btn_payment {
  background-color: var(--yellow) !important;
  color: var(--header-bg) !important;
}

.quote_btn_accept[disabled] {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.quote_btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.quote_slider_contain {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 5px;
  margin: 19px 0px 30px 0;
}

.card-side-bar::-webkit-scrollbar {
  display: none;
}

.px-20 {
  padding: 0px 20px !important;
}

.chat-quotes-button {
  width: 100%;
  /* margin-bottom: 19px; */
  background-color: var(--yellow);
  font-family: "jokker-regular";
  font-size: 18px;
  color: var(--header-bg);
  height: 56px;
  border-radius: 8px;
  cursor: pointer;
}

.quotes-view-btn {
  background-color: var(--white);
  color: var(--black);
  border: 1px solid var(--black);
  cursor: pointer;
}

.quotes-view-button {
  background-color: var(--black);
  color: var(--white);
  border: 1px solid var(--white);
  cursor: pointer;
}

.quote_slider_inner_text {
  opacity: 0.5;
  font-family: "jokker-semibold";
}

.quote_card_coverages_text {
  color: var(--violet) !important;
  cursor: pointer;
}

.modal_header_coverages {
  padding: 22px;
  /* display: flex;
  align-items: center; */
  border-bottom: 2px solid #e6d9d6;
}

.coverages_text {
  color: var(--black);
  font-size: 24px;
  line-height: 22px;
  font-family: "jokker-bold";
  text-align: center;
  width: 100%;
}

.coverages_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px;
  border-bottom: 2px solid #222c4e;
}

.coverages_item p {
  padding: 0px 30px;
  font-size: 16px;
  font-family: "jokker-medium";
  line-height: 22px;
  color: var(--white);
  width: 50%;
}

.coverages_item p:last-child {
  text-align: end;
}

.convertAgentTitle {
  font-size: 18px;
  font-family: "jokker-semibold";
  line-height: 22px;
  color: var(--white);
  text-align: center;
}
.convertAgentTitle span{
 color: var(--turquoise);
 text-decoration: underline;
 cursor: pointer;
}
.scheduleBox {
  width: 96%;
  height: 177px;
  border-radius: 8px;
  border: 1px solid #ffffff33;
}

.heading {
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "jokker-regular";
}

.scheduleBoxBorder {
  border: 1px solid #ffffff33;
}

.timeScheduleMain {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 20px;
}

.timeScheduleMain1 {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 20px;
  margin-right: 55px;
}

.timeSchedule {
  color: var(--white);
}

.sundayClosed {
  color: var(--white);
  display: flex;
  margin-top: 12px;
  justify-content: center;
  align-items: center;
  margin-left: 160px;
}

.radioBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.radioBtn .termsRadioBtn {
  margin: 0 !important;
}

.termsRadioBtn {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000;
  background-color: #fff;
  cursor: pointer;
  outline: none;
  background-clip: content-box;
  border-color: var(--white);
}

.termsRadioBtn:checked {
  background-color: #31eaf2;
}

.termsLink {
  color: var(--white);
}

.covertAgentDetails {
  border: 1px solid #ffffff33;
  border-radius: 8px;
}

.covertAgentHeader {
  font-size: 18px;
  font-family: "jokker-semibold";
  line-height: 22px;
  color: var(--white);
  text-align: center;
  padding: 20px 0px 10px 0px;
  border-bottom: 1px solid #ffffff33;
}

.covertAgentName {
  font-size: 18px;
  font-family: "jokker-semibold";
  line-height: 22px;
  color: var(--white);
  padding-bottom: 20px;
  border-bottom: 1px solid #ffffff33;
}
.submithover {
  transition: all 0.2s;
  text-align: center;
}
.submithover:hover {
  background-color: var(--header-bg) !important;
  color: white !important;
}
.covertAgentBody {
  padding: 20px;
}

.convertAgentContain {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.text-white {
  color: var(--white) !important;
}

.agent-details {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.agent-value {
  font-size: 18px;
  line-height: 22px;
  color: var(--white);
  margin: 0;
}

.agent-label {
  margin: 0;
  font-size: 18px;
  font-family: "jokker-semibold";
  line-height: 22px;
  color: #a3a7b5;
  margin-bottom: 10px;
}

.agent-connect-btn {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.chat-offline-btn {
  border: 1px solid var(--white) !important;
  background-color: transparent !important;
  color: var(--white) !important;
  padding: 11px 32px;
}

.request-call-btn {
  padding: 11px 32px !important;
}

.quote_slider_provider {
  font-family: "bunch-bold" !important;
  font-size: 20px !important;
  opacity: 1 !important;
}

/* .quote-card-purchased::before {
  position: absolute;
  right: -68px;
  content: "Purchased";
  height: 35px;
  width: 100px;
  background-color: var(--yellow);
  color: var(--white);
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transform: rotate(90deg);
} */

.quote-card-accepted::before {
  position: absolute;
  right: -68px;
  content: "Accepted";
  height: 35px;
  width: 100px;
  background-color: var(--green);
  color: var(--white);
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transform: rotate(90deg);
}

.mt-30 {
  margin-top: 30px;
}

.quote-card h4 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 700;
}

.quote-card-is-accepted {
  background-color: var(--utility-green);
  border-radius: 5px;
  color: var(--black);
  font-size: 16px;
  padding: 8px 16px;
  /* margin-right: 10px; */
  text-transform: capitalize;
  font-family: "jokker-medium";
}

.quote-card-prise {
  /* background-color: var(--utility-green); */
  border-radius: 4px;
  color: var(--white);
  font-size: 18px;
  /* padding: 11px 13px; */
  font-family: "jokker-medium";
}

.quote-purchased {
  background-color: var(--utility-green);
  color: var(--header-bg);
}

.quote-submitted-prise {
  background-color: var(--utility-green);
  border-radius: 4px;
  color: var(--header-bg);
  font-size: 16px;
  padding: 8px 16px;
  font-family: "jokker-medium";
}

.jokker-semibold {
  font-family: "jokker-semibold" !important;
}

.quote-name {
  font-size: 18px;
  font-family: "jokker-bold";
  line-height: 22px;
  text-align: left;
  color: var(--black);
  text-transform: capitalize;
}

.font-12 {
  font-size: 12px;
}

.quote-card-Status {
  padding: 8px 16px;
  background: var(--utility-green);
  text-transform: capitalize;
  line-height: 24px;
  align-items: center;
  font-family: "jokker-regular";
  font-size: 16px;
  color: var(--black);
  border-radius: 8px;
}

.preview-right-btn {
  padding: 19px 0px;
  width: 100%;
  justify-content: center;
  display: flex;
  gap: 24px;
}

.active-insurance {
  color: var(--utility-green) !important;
  opacity: 1 !important;
}

.other-status-insurance {
  opacity: 1 !important;
}

.closed-insurance {
  color: var(--utility-red) !important;
  opacity: 1 !important;
}

.purchased-insurance {
  color: var(--utility-green) !important;
  opacity: 1 !important;
}

.drafts-insurance {
  color: var(--violet) !important;
  opacity: 1 !important;
}

/* / */

.insurance-details {
  background-color: var(--white);
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 25px;
  border-radius: 8px;
  padding: 20px;
}

.insurance-details .separate-line {
  width: 1px;
  background-color: var(--header-bg);
  opacity: 0.2;
}

.insurance-title-text {
  font-size: 18px;
  font-family: "jokker-medium";
  line-height: 22px;
  color: var(--header-bg);
  opacity: 0.5;
  text-align: center;
  text-transform: capitalize;
}

.insurance-title {
  font-size: 18px;
  font-family: "jokker-medium";
  line-height: 22px;
  color: var(--header-bg);
  margin-bottom: 10px;
}

.quote-card-purchased {
  background-color: var(--black) !important;
  color: var(--white) !important;
}

.quote-card-details {
  margin-top: 20px;
  border-top: 1px solid var(--pearl);
  border-bottom: 1px solid var(--pearl);
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quote-card-label {
  font-size: 14px;
  font-weight: 800;
  min-width: 100px;
  font-family: "jokker-medium";
}

.quote-card-label-detail {
  font-size: 13px;
  color: var(--dusty-gray);
}

.quote-card-document {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.quote-accept-button {
  padding: 8px 16px;
  border-radius: 4px;
  color: var(--header-bg);
  font-size: 13px;
  cursor: pointer;
  transition: 0.5s;
  border: unset;
  background-color: var(--yellow);
}

.chat-quotes-button[disabled] {
  /* background-color: grey; */
  opacity: 0.5;
  cursor: not-allowed !important;
}

.quote-accept-button:hover {
  background-color: var(--header-bg);
  color: var(--white);
}

.quote-card-document-icon {
  height: 31px;
  width: 31px;
  border: 1px solid var(--light-black);
  border-radius: 4px;
}

.profile-head {
  background-color: var(--white);
  height: 120px;
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  color: var(--header-bg);
  display: flex;
  align-items: center;
}

.profile-input-section {
  border: 1px solid var(--mercury-white);
  border-radius: 24px;
  padding: 20px 40px 40px 40px;
  background-color: var(--white);
  /* margin-bottom: 80px; */
  position: relative;
  margin-top: 30px;
}

.survey-section {
  border: 1px solid var(--mercury-white);
  border-radius: 24px;
  padding: 40px;
  background-color: var(--white);
  /* margin-bottom: 80px; */
  position: relative;
  margin-top: 30px;
}

.survey-type {
  text-align: center;
  cursor: pointer;
}

.survey-type.active {
  opacity: 1;
}

.survey-type.active .survey-type-img {
  border-color: var(--turquoise);
  background-color: var(--turquoise);
}

.survey-opacity {
  opacity: 0.5;
}

.survey-type p {
  font-size: 18px;
  font-family: "jokker-semibold";
  color: var(--header-bg);
  margin-bottom: 0px;
}

.survey-type-img {
  width: 150px;
  height: 108px;
  border-radius: 24px;
  border: 1px solid var(--header-bg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-title {
  font-size: 18px;
  font-family: "jokker-bold";
  color: var(--header-bg);
  margin: 20px 0px;
}

.profile-thumb-main {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin-left: auto;
  display: inline-table;
  margin-right: auto;
}

.profile-input {
  width: 100%;
  /* max-width: 353px; */
}

.flex_box_profile {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  width: -webkit-fill-available;
  width: -moz-available;
}

.card-side-bar {
  display: inline-block;
  position: relative;
  float: right;
  overflow-y: auto;
  height: calc(100vh - 84px);
  width: 440px;
  /* padding: 20px 10px; */
  background-color: var(--white);
  box-shadow: -6px 0px 40px #0000000d;
  z-index: 1;
}

.quotes_slider_header {
  color: var(--header-bg);
  font-size: 24px;
  font-family: "jokker-bold";
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 25px 40px; */
  height: 81px;
  box-shadow: 0px 6px 12px #00000008;
  border-bottom: 2px solid #ebecef;
}

.quotes-list-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #09143a;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  display: none;
}

.card-side-bar .css-1ab6zav-MuiPaper-root-MuiAccordion-root:before {
  content: unset !important;
}

.card-side-bar .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}

.card-side-bar .css-o4b71y-MuiAccordionSummary-content {
  margin: 0px !important;
}

.agent_accordion .MuiAccordionSummary-root {
  padding: 20px !important;
}

.compare_quotes_accordion .MuiAccordionSummary-root {
  color: var(--turquoise);
  font-size: 18px;
  text-transform: capitalize;
  line-height: 22px;
  font-family: "jokker-semibold" !important;
  border-bottom: 1px solid var(--turquoise);
}

.agent_accordion {
  background-color: var(--header-bg) !important;
  border-radius: 12px !important;
  margin: 20px 0px !important;
}

.compare_quotes_accordion {
  /* border-bottom: 1px solid var(--turquoise) !important; */
  background-color: var(--header-bg) !important;
  margin: 10px 0px !important;
}

.compare_quotes_details {
  color: var(--white);
  font-size: 18px;
  line-height: 22px;
  font-family: "jokker-semibold";
  padding: 20px 0px 0px 0px !important;
}

#compare_quotes_summary {
  padding: 0px !important;
}

.card-header-text {
  font-family: "jokker-bold";
  font-size: 18px;
  color: var(--white);
}

/* .Mui-expanded {
  margin: 0 !important;
} */
.support-chat .chat-board {
  height: 100%;
  width: 100%;
  display: block;
}

.agent-chat .chat-board {
  height: 100%;
  width: -webkit-fill-available;
  background-color: var(--white);
  /* padding: 0px 40px; */
  /* width: calc(100% - 440px); */
  display: inline-block;
}

.w-100 {
  width: 100% !important;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.inner-form {
  margin-top: 40px;
}

/* .prifix { */
/* position: relative;
  display: flex;
  align-items: center; */
/* } */
/* .prifix::after { */
/* position: absolute; */
/* content: url("../assets/images/information.png");
  margin-left: 5px;
  margin-top: 4px; */
/* } */
.prifix {
  position: relative;
  /* display: inline-flex; */
  align-items: center;
}

.prifix .tooltipText {
  position: absolute;
  visibility: hidden;
  width: 120px;
  background-color: var(--selective-light-yellow);
  color: var(--black);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: calc(100% + 5px);
  left: 0;
  z-index: 1;
}

.prifix:hover .tooltipText {
  visibility: visible;
}

 .tooltip svg {
  margin-left: 5px;
  margin-bottom: -5px;
}

.inner-form-heading {
  padding-bottom: 30px;
  font-family: "jokker-medium";
  font-size: 18px;
  margin: 0;
}

.slider-label {
  color: var(--light-black);
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.check-info {
  font-size: 16px;
  color: var(--black);
  font-weight: 500;
}

.pb-0 {
  padding-bottom: 0px;
}

.ml-30 {
  margin-left: 30px;
}

.flood-start-button {
  width: 119px !important;
  height: 44px !important;
  margin: unset !important;
  border-radius: 4px !important;
  min-width: unset !important;
}

.roofOption p {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  /* font-weight: 600; */
  font-size: 16px;
}

.roofOption {
  cursor: pointer;
    text-align: center;
    padding: 15px 10px 0px;
    border-radius: 20px;
    transition: all ease 0.1s;
    /* margin-right: 20px; */
    width: 120px;
}

.roofOption.active .roofOptionImg,
.roofOption:hover .roofOptionImg {
  border: 2px solid #000;
  filter: grayscale(0);
  background: #f0f4fc;
}

.roofOption.active p {
  color: black;
}

.roofOption p {
  /* color: grey; */
}
.radio-icon-preview {
  display: flex;
  flex-direction: column;
  width: max-content;
  text-align: center;
}
.multiEntryCardTitle {
  font-size: 14px;
  font-family: "jokker-bold";
  color: var(--header-bg);
  position: absolute;
  top: -9px;
  background: var(--white);
  left: 20px;
  padding: 0px 10px;
}
.roofOptionImg {
  align-content: center;
  border: 2px solid #b1b0b0;
  border-radius: 100px;
  display: grid;
  height: 80px;
  justify-content: center;
  width: 80px;
  margin: 0 auto;
}
.roofOptionImg + p {
  width: 120px;
  text-align: center;
  margin-bottom: 0px;
}
.grayscale {
  filter: grayscale(1);
}

.roofLabel {
  color: var(--light-gray);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.mainNoRecordFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 25px;
  font-size: 22px;
  font-weight: 800;
}

.agentNotFound {
  text-align: center;
  font-weight: 800;
}

.outline-primary-btn {
  border: 2px solid var(--selective-light-yellow);
  color: var(--selective-light-yellow);
  margin-left: auto;
  margin-right: unset;
  font-size: 18px;
  height: 42px;
  padding: 8px 25px;
  width: 136px;
  display: flex;
  gap: 10px;
  transition: 0.5s;
  background-color: var(--white);
}

.outline-primary-btn:hover {
  color: var(--white);
  background-color: var(--selective-light-yellow);
}

.outline-primary-btn span {
  min-width: 75px;
}

.accordion {
  max-width: 768px;
}

.accordion-item {
  list-style: none;
}

.accordion-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--light-silver);
  padding: 1em;
}

.accordion-toggle:hover {
  background-color: var(--alto);
}

.accordion-toggle h3 {
  margin: 0;
}

.accordion-content {
  background-color: var(--gallery);
  padding: 1em;
}

.font-15 {
  font-size: 15px;
}

/* .document-footer img {
  width: auto;
  height: 50px;
} */
.insurance-main {
  display: flex;
  flex-direction: row;
  gap: 34px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.insurance-popup {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}

.insurance-card {
  height: 206px;
  width: 206px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  background: var(--white) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 34px;
  opacity: 1;
  cursor: pointer;
}

.insurance-card:hover {
  border: 1px solid var(--selective-light-yellow);
}

.add-more-btn {
  min-width: 80px;
  height: 40px !important;
  font-size: 13px !important;
}

.remove-icon {
  position: absolute;
  top: -10px;
  right: -8px;
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  padding: 5px;
  cursor: pointer;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
}

.preview-edit {
  position: absolute;
  top: -10px;
  right: -8px;
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  padding: 5px;
  cursor: pointer;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
}

.w-full {
  width: 100%;
}

.Mui-error ul {
  padding: 0px 0px 0px 10px;
  margin: 0px;
}

.Mui-error ul li {
  list-style: circle !important;
}

.multi-entry-field .customer-information-input {
  width: 48.7% !important;
}

.multi-entry-field .customer-information-full-input {
  width: 100% !important;
}

.main-dialog {
  min-height: 100px;
  min-width: 300px;
  /* padding: 10px; */
  background-color: var(--header-bg);
  border-radius: 24px;
  overflow: hidden;
  /* padding: 40px; */
  margin-bottom: 20px;
}
.nobg .MuiDialog-container .MuiDialog-paperScrollPaper {
  background-color: transparent !important;
  box-shadow: none !important;
}
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 800;
  padding: 10px;
}
.dialog-header {
  padding: 18px;
  background-color: var(--turquoise);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.dialog-header div {
  font-family: "Jokker-bold";
  font-size: 24px;
}
.dialog-btn {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
  padding: 0 30px 30px;
}

.dialog-body {
  padding: 30px;
  font-family: "jokker-semibold" !important;
  font-size: 18px;
  color: white;
  margin-bottom: 20px;
}

.customer-radio-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.file-upload-box {
  border: 2px dashed var(--header-bg);
  border-radius: 24px;
  min-height: 308px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 53px;
  font-weight: 400;
  font-size: 15px;
}

.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-upload-text {
  text-align: center;

  color: var(--header-bg);
  font-size: 18px;
  font-family: "jokker-semibold";
}

.file-link,
.file-upload-text {
  /* text-decoration: underline; */
  max-width: 100%;
  word-break: break-all;
  font-family: "jokker-semibold";
}

.file-link {
  text-decoration: underline;
}

.insurance-header {
  position: sticky;
  z-index: 99;
  top: 83px;
  display: flex;
  align-items: center;
  height: 80px;
  background-color: var(--white);
}

.insurance-header-flex {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.breadcrumbs-contain {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.breadcrumbs-step {
  color: var(--header-bg);
  text-align: left;
  font-family: "jokker-medium";
  font-size: 18px;
  /* opacity: 0.5; */
  cursor: pointer;
}

.saveAsDraftBtn {
  width: 177px;
  height: 53px;
  border-radius: 8px;
}

.save-as-button {
  display: flex;
  justify-content: end;
  padding: 20px 0px;
}

.breadcrumbs-step:nth-last-child(-n + 2) {
  opacity: 1;
  cursor: context-menu;
}

.gap-40 {
  gap: 40px;
}

.gap-row-30 {
  row-gap: 30px;
}
.gap-row-40 {
  row-gap: 40px;
}
.gap-column-30 {
  column-gap: 30px;
}

.p-40 {
  padding: 40px;
}

.pt-0 {
  padding-top: 0px;
}

.card-save-btn {
  width: 105px;
  height: 53px;
  border-radius: 8px;
}

.vaultPinContain {
  background-color: var(--header-bg);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  padding: 60px 0px;
  /* max-width: 610px; */
  margin-left: auto;
  margin-right: auto;
}

.vaultPinTitle {
  color: var(--white);
  font-size: 24px;
  font-family: "jokker-bold";
  padding: 20px 0px 10px 0px;
  margin: 0;
}

.vaultPinBtn {
  width: 375px;
  height: 56px;
  border-radius: 8px;
  margin: auto;
}

.forgetVaultPin {
  color: var(--white);
  font-size: 18px;
  font-family: "jokker-medium";
  cursor: pointer;
  margin: 10px 0px 0px 0px;
}

.doc_error {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 10px !important;
}

.vaultPinBtn:hover {
  background-color: var(--yellow);
  color: var(--primary);
}

.vaultPinDescription {
  color: var(--white);
  text-align: center;
  font-size: "jokker-regular";
  margin: 0;
}

.otpContainer {
  /* margin: 5% auto; */
  display: flex;
  flex-direction: row;
  gap: 17px;
  justify-content: center;
}

.pin-section {
  padding: 30px 0px 40px 0px;
}

.otpInput {
  width: 75px;
  height: 45px;
  border: none;
  font-family: "jokker-bold";
  background-color: var(--header-bg);
  border-bottom: 2px solid var(--white) !important;
  font-size: 30px;
  text-align: center;
  color: var(--white);
  padding: 0;
}

.otpInput:hover,
.otpInput:focus,
.otpInput:focus-visible {
  outline: none;
  border: 1px solid var(--primary);
}

.overview_insurance_card_policy_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overview_insurance_card_policy_title h3 {
  text-transform: capitalize;
}

.overview_insurance_card_policy_status {
  border-radius: 8px;
  color: var(--header-bg);
  font-size: 16px;
  padding: 10px 20px;
  text-align: center;
  font-family: "jokker-medium";
  text-transform: capitalize;
  background-color: var(--utility-green);
}

.policy_status_expire {
  background-color: var(--utility-red);
  color: var(--white) !important;
}

.policy_status_renewal {
  background-color: #1e2e78;
  color: var(--white) !important;
}

.pt-40 {
  padding-top: 40px;
}

.profile-thumb-main .secondUserIcon {
  height: 100px;
  width: 100px;
}

.mb-30 {
  margin-bottom: 30px;
}

.select-box {
  display: flex !important;
  margin-bottom: 8px !important;
  align-items: end !important;
  position: relative;
}

.input-box {
  position: relative;
}

.select-box p {
  position: absolute;
  bottom: -19px;
  left: 7px;
}

.input-box p {
  position: absolute;
  bottom: -10px;
  left: 7px;
}

.mt-50 {
  margin-top: 50px;
}

.checkbox-contain {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox-contain span {
  padding: 0 !important;
}

.checkbox-label {
  font-size: 18px;
  color: var(--granite-gray);
  font-family: "jokker-medium";
}

.verification-status {
  background: var(--violet);
  border-radius: 5px;
  padding: 8px 15px;
  text-transform: capitalize;
  color: var(--white);
  font-family: "jokker-regular";
}

.verification-status-complete {
  background: var(--utility-green);
}

.insurance-marketplace-card {
  background-color: var(--header-bg);
  height: 352px;
  border-radius: 24px;
}

.option_card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 0.5px solid var(--white);
}

.option_card_body {
  color: var(--white);
  font-size: 16px;
  text-align: left;
  font-family: "jokker-regular";
  line-height: 24px;
}

.loan_reference_card {
  border-radius: 24px;
  background-color: var(--white);
  padding: 40px;
}

.address_card {
  border-radius: 24px;
  background-color: var(--white);
  padding: 40px;
}

.insurance_login_card {
  border-radius: 24px;
  background-color: var(--white);
  padding: 40px;
}

.upload_existing_card {
  border-radius: 24px;
  background-color: var(--white);
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container_width {
  /* padding: 0px 50px; */
  width: -webkit-fill-available;
  width: -moz-available;
  /* max-width: 1240px; */
  /* max-width: 86%; */
  margin: auto;
  padding: 0 40px;
  position: relative;
}

.agent_container_width {
  padding: 0px 40px;
  width: -webkit-fill-available;
  width: -moz-available;
  /* max-width: 75%; */
  margin: auto;
  position: relative;
}

/* .preview-container {
  max-width: 82% !important;
} */

.preview-breadcrumb {
  flex-direction: column;
}

.menu_item {
  color: var(--primary) !important;
  text-align: left;
  font-size: 18px !important;
  font-family: "Jokker-medium" !important;
  gap: 14px;
}

.delete-policy {
  color: var(--utility-red) !important;
}

.delete-popup {
  width: 613px;
  height: auto;
  border-radius: 30px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-popup img {
  object-fit: contain;
}
.mx720{
  max-width: 720px !important; 
}
.close-icon {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  z-index: 1;
}
.lead-more-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}
.lead-more-btn .view_button{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.delete_model_main {
  margin: 30px 0px;
}

.delete_model_main h1 {
  text-align: center;
  font-size: 18px;
  font-family: "Jokker-semibold" !important;
  color: var(--header-bg);
  margin: 0;
  margin-bottom: 20px;
}

.delete_model_main p {
  text-align: center;
  font-size: 18px;
  font-family: "Jokker-semibold";
  color: var(--header-bg);
  margin: 0;
}

.delete_mode_btn {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.delete-model-btn {
  min-width: 93px;
  height: 53px;
  background-color: var(--yellow);
  color: var(--header-bg);
  border: unset;
  border-radius: 8px;
  transition: 0.5s;
  display: block;
  margin: auto;
  font-family: "jokker-semibold";
  font-size: 18px;
  cursor: pointer;
}

.delete-yes-btn {
  border: 1px solid var(--white);
  background-color: transparent;
  color: var(--white);
}

.notification_card {
  padding: 30px 40px;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 6px 12px #00000008;
  display: flex;
  justify-content: space-between;
}

.notification_card:hover {
  background-color: #30ebf2;
}

.notification-read {
  background-color: var(--dashboard-bg) !important;
  box-shadow: none !important;
}

.notification_left {
  display: flex;
  gap: 27px;
  align-items: center;
}

.notification_contain h3 {
  font-size: 18px;
  color: var(--black);
  font-family: "jokker-bold";
  line-height: 22px;
  margin: 0px 0px 10px 0px;
}

.notification_contain p {
  color: var(--granite-gray);
  font-size: 18px;
  line-height: 25px;
  font-family: "jokker-medium";
  margin: 0;
}

.notification_contain p span {
  cursor: pointer;
  font-family: "jokker-Semibold";
  color: var(--violet);
}

.notification_right {
  font-family: "jokker-medium";
  font-size: 18px;
  line-height: 25px;
  color: var(--granite-gray);
  opacity: 0.5;
  min-width: 135px;
  text-align: end;
}
.model-contain-main-upload .model-contain-main-responsive .model-contain {
  max-height: unset !important;
  overflow: unset !important;
}
.model-contain-main-upload {
  width: 90% !important;
}

.modal_bg {
  background-color: var(--dashboard-bg);
  /* width: 400; */
  border-radius: 24px;
  overflow: hidden;
  /* padding: 40px; */
  margin-bottom: 20px;
}

.model-contain {
  /* max-height: 50vh; */
  overflow-y: auto;
  /* padding-right: 40px; */
}

.model-contain-main-responsive-upload {
  padding: 40px !important;
  /* max-height: 50vh; */
  overflow-y: auto;
  /* min-height: 425px; */
  /* max-height: 515px; */
  /* height: calc(100% - 140px); */
}

.leander-user-responsive-model {
  max-height: 80vh;
  overflow-y: auto;
}

.login-link-title {
  font-family: "jokker-semibold";
  text-align: center;
  font-size: 18px;
  color: var(--white);
  margin: 0px 0px 30px 0px;
}

.get-policy-text {
  text-align: center !important;
  margin-bottom: 20px !important;
}
.modal_body {
  background-color: var(--header-bg);
}
.ivs-leander-title {
  font-size: 16px;
  font-family: "jokker-regular";
}
.footerhover a:hover {
  color: var(--white);
}
.cursor-pointer {
  cursor: pointer;
}
.login-link-message {
  font-family: "jokker-semibold";
  text-align: justify;
  font-size: 18px;
  line-height: 25px;
  color: var(--white);
  margin: 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: var(--turquoise);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.custombtn {
  font-family: "jokker-bold" !important;
  color: var(--header-bg) !important;
  background-color: transparent !important;
  padding: 0px;
  position: absolute;
  bottom: 0px;
}
.handwidth {
  width: 25%;
}

.manageCardAppliedDiscount {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.btnAppliedDiscount {
  margin-top: auto;
  border-bottom: 1px dashed #09143a66;
  margin-bottom: 20px;
}

.model-contain::-webkit-scrollbar-thumb:window-inactive {
  background: var(--turquoise);
}

.pr-0 {
  padding-right: 0px !important;
}

.vaultPinBtn[disabled] {
  cursor: not-allowed;
}

.main_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  max-width: 600px;
  width: 100%;
  /* height: calc(90% + 50px); */
  /* max-height: 100%; */
  /* max-height: 80vh ; */
  /* margin: 10px; */
  /* max-height: 80vh; */
  /* margin: 20px 0; */
}

.leander_user_model {
  max-width: 85% !important;
}

.modal_coverages {
  max-width: 400px;
}

.main_modal:focus-visible {
  outline: none !important;
}

.main_modal_bg #modal-modal-title {
  font-family: "jokker-semibold" !important;
  font-size: 18px;
  color: var(--header-bg);
  margin-top: 0px;
  margin-bottom: 20px;
}

.white-color {
  color: var(--white) !important;
}

.main_modal_bg #modal-modal-title.mainPopup {
  color: var(--white) !important;
  font-size: 18px !important;
  font-family: "jokker-medium" !important;
  text-align: center;
}

.existingInsuranceLoginBtn {
  color: #09143a;
  background-color: #ffcd00 !important;
  width: 400px;
  height: 60px;
  border-radius: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-family: "jokker-medium";
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dividerMainPopup {
  margin: 40px 0px;
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.23);
  /* opacity: 0.23; */
  position: relative;
}

.dividerMainPopup div {
  position: absolute;
  width: 60px;
  height: 21px;
  font-size: 18px;
  transform: translate(-50%, -50%);
  text-align: center;
  top: 50%;
  left: 50%;
  background-color: var(--black);
  color: var(--white);
}

.white-border {
  border: 2px solid var(--white) !important;
}

/* .white-border:checked {
  border: 2px solid var(--white) !important;
} */
.bg-dark-blue {
  background-color: var(--header-bg) !important;
}

.bg-white {
  background-color: var(--white) !important;
}

.question-contain {
  display: flex;
  row-gap: 10px;
  column-gap: 30px;
  /* margin-top: 30px; */
  flex-wrap: wrap;
}

.modal_tag span {
  font-size: 12px;
  font-family: "jokker-medium" !important;
  letter-spacing: 2.4px;
  color: var(--header-bg);
}

.modal_tag img {
  width: 15px;
}

.modal_tag {
  padding: 9px 16px;
  width: fit-content;
  display: flex;
  background-color: rgba(30, 46, 120, 0.1);
  gap: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  align-items: center;
}

.insurance_modal_tag {
  width: fit-content;
  display: flex;
  gap: 10px;
  border-radius: 4px;
  align-items: center;
}

/* .insurance_modal_tag img {
  width: 50px;
} */

.combine_modal_tag img {
  width: 100px;
}

.insurance_modal_tag span {
  font-size: 24px;
  font-family: "jokker-bold" !important;
  color: var(--header-bg);
}

.main_modal .survey_card_header input {
  background-color: var(--dashboard-bg);
}

.main_modal .survey_card_header label {
  font-size: 18px;
}
.coverage_vehicle {
  /* text-transform: capitalize; */
  font-size: 16px;
  margin: 0px 0px 20px 0px;
  /* font-family: "jokker-bold"; */
  color: var(--header-bg);
  /* position: absolute; */
  /* top: -9px; */
  background: var(--white);
  /* left: 20px; */
  /* padding: 0px 10px; */
}
.coverage_vehicle_floating {
  /* text-transform: capitalize; */
  font-size: 16px;
  margin: 0px 0px 20px 0px;
  /* font-family: "jokker-bold"; */
  color: var(--header-bg);
  position: absolute;
  top: -9px;
  background: var(--white);
  left: 20px;
  padding: 0px 10px;
}
/* .coverage_multi {
  padding: 0px 20px;
} */
.coverage_vehicle b {
  color: var(--header-bg);
  font-family: "jokker-bold";
}
.lead-submit-content{
  min-height: 250px;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal_header {
  align-items: center;
  background-color: var(--turquoise);
  display: flex;
  padding: 22px;
  position: relative;
  height: 24px;
}
.modal_header .modal_logo {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.modal_logo {
  width: 133px;
  height: 33px;
  margin: 0 auto;
}

.main_modal_bg {
  background: rgba(9, 20, 58, 0.3);
}

.model_p {
  font-family: "jokker-semibold";;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.pre-fill_question {
  font-family: "jokker-medium";
  line-height: 25px;
  font-size: 18px;
  color: var(--white);
  margin-top: 40px;
}

.verification_forward {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.modal_button {
  background-color: var(--yellow);
  color: var(--header-bg);
  font-family: "jokker-semibold";
  font-size: 18px;
  border-radius: 12px;
  padding: 20px 130px;
  border: unset;
  margin: 20px auto;
  display: block;
  cursor: pointer;
}

.accordianDetails {
  background-color: var(--turquoise);
  border-radius: 40px;
  color: var(--black);
  width: fit-content;
  margin: 8px 9px 5px 13px;
}

.modal_devider {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 25px auto 25px auto;
}

.appliedDiscount {
  border-top: 1px dashed #09143a66;
  border-bottom: 1px dashed #09143a66;
  box-shadow: none !important;
  margin-bottom: 16px;
}

.bindableTermsAndCondition {
  display: flex;
  margin-top: 20px;
  background-color: var(--white);
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  width: calc(100% - 32px);
  gap: 10px;
  align-items: start;
}

.lead_validation_message {
  margin-top: 20px;
  /* background-color: var(--white); */
  /* border-radius: 8px; */
  padding: 0px 24px;
}

.lead_validation_message ul {
  padding: 0px 10px 0px 20px;
  margin: 0;
}

.multi-entry-question {
  margin: 20px 0 0 10px;
  border: 1.5px solid var(--black);
  padding: 20px;
  border-radius: 8px;
}

.lead_validation_message li {
  list-style: circle !important;
  line-height: 20px;
  letter-spacing: 0.5;
  font-size: 16px;
  font-family: "jokker-semibold";
  color: var(--white);
}

.bindableTermsAndCondition label {
  width: fit-content;
  letter-spacing: 0.25px !important;
  line-height: 20px;
  font-family: "Jokker-bold";
  cursor: pointer;
  font-size: 18px;
}

.bindableTermsAndCondition .termsRadioBtn {
  border-color: var(--header-bg) !important;
  width: 16px;
  height: 16px;
  margin-top: 3px !important;
}

.MuiAccordion-region {
  display: flex;
  flex-wrap: wrap;
}

.modal_devider span {
  height: 1.5px;
  width: -webkit-fill-available;
  width: -moz-available;
  background-color: #FFFFFF33;
  /* opacity: 0.2; */
}

.disabledPolicyCard {
  cursor: not-allowed;
  opacity: 0.5;
}

.disabledUploadDocument {
  cursor: no-drop !important;
  opacity: 0.5;
}

.modal_devider p {
  font-size: 18px;
  font-family: "jokker-semibold";
}
.multiEntryCard {
  border: 1px solid black;
  padding: 20px;
  border-radius: 8px;
}
.bg-light-plaster {
  background-color: var(--dashboard-bg) !important;
}

.upload_policy {
  padding: 22px 44px;
  border: 1px solid #ffffff80;
  /* background-color: var(--white); */
  border-radius: 12px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.cat_box {
  padding: 16px 32px;
  border: 1px solid var(--white);
  color: var(--white);
  border-radius: 8px;
  font-family: "jokker-medium";
  font-size: 18px;
  cursor: pointer;
}
.context-menu {
  cursor: context-menu !important;
}
.info-icon {
  /* display: flex;
  align-items: center;
  gap: 5px; */
}
.info-icon img {
  filter: brightness(0) invert(0);
  height: 16px;
  margin-left: 5px;
  margin-bottom: -2px;
}
.info-icon span {
  height: 12px;
}
.selectedCategory {
  background-color: var(--white);
  color: var(--header-bg);
  border-color: var(--header-bg);
}

.ivs-description {
  color: var(--black);
  font-size: 18px;
  margin: 20px 0;
  max-width: 851px;
  line-height: 25px;
}

.disable-category {
  cursor: not-allowed;
}

.disable-category .Mui-disabled {
  cursor: not-allowed;
}

.cat_box.active {
  background-color: var(--turquoise);
  color: var(--header-bg);
  border-color: var(--turquoise);
}

.upload_document_header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload_document_header #modal-modal-title {
  margin-bottom: 0px !important;
}

.upload_document_header button {
  /* width: 105px;
  height: 53px; */
  color: var(--white);
  font-size: 18px;
  font-family: "jokker-medium";
  padding: 16px 32px;
  border: 1px solid var(--white);
  background-color: var(--header-bg);
  border-radius: 8px;
}

#model-policy-id-card .file-upload-text {
  color: var(--white) !important;
}

.model-contain-main-upload .file-upload-text {
  color: var(--white) !important;
}

#model-verification .file-upload-text {
  color: var(--white) !important;
}

.vault_tab_label .css-heg063-MuiTabs-flexContainer {
  justify-content: center;
}

/* .vault_tab_label .css-qr7z53-MuiButtonBase-root-MuiTab-root {
  font-family: "jokker-medium";
  font-size: 18px;
  text-transform: unset;
  padding: 10px 50px;
  color: var(--header-bg);
} */
.policy-tabs {
  font-family: "jokker-medium" !important;
  font-size: 18px !important;
  text-transform: unset !important;
  padding: 10px 50px !important;
  color: var(--header-bg) !important;
}

.id_box {
  padding: 17px 14px;
  box-shadow: 0px 6px 12px #00000008;
  border-radius: 8px;
  background-color: var(--white);
  text-align: end;
}

.id_img {
  height: 169px;
  width: 100%;
  border-radius: 8px;
  background-color: #f5f5f5;
  object-fit: cover;
}
/* 
.id_box svg {
  margin-top: 17px;
} */

.policy_menu_item {
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.QuoteListToggal {
  display: none !important;
}

.chat_container_width {
  width: 1233px;
  /* width: 100%; */
  /* background-color: red; */
  margin-left: auto;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
}

.mr-30 {
  margin-right: 30px;
}

.pr-10 {
  padding-right: 10px;
}

.container_loadMore {
  margin-left: auto;
  margin-right: auto;
}

.loadMoreBtn {
  background-color: #ffcd00;
  color: #09143a;
  font-size: 18px;
  font-family: "jokker-Medium";
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  width: 126px;
  border: none;
  padding: 10px;
  margin: 20px 0px;
}

.multiEntryTwoField .MuiFormHelperText-root {
  display: block !important;
}

.form-radio-multi-field {
  width: 45% !important;
}

.my-chats-section {
  background-color: var(--header-bg);
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 6px 12px #00000008;
  height: 100%;
}

.my-chat-title {
  padding: 25px 0px 25px 10px;
  color: var(--white);
  font-size: 24px;
  font-family: "jokker-bold";
  line-height: 24px;
  border-bottom: 2px solid #454e6a;
}

.my-chat-list {
  padding-top: 28px;
  max-height: calc(100vh - 310px);
  overflow-y: scroll;
}

.my-chat-content {
  padding: 22px 30px;
  box-shadow: 0px 6px 12px #00000008;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.my-chat-active {
  background-color: var(--violet);
  /* position: relative; */
}

.quote-list-model .MuiDrawer-paper {
  width: 420px;
  height: calc(100vh - 206px);
  bottom: 0px;
  right: 40px;
  border: unset;
  top: unset;
  background: #f2f8ff;
}

.active-chat-dot {
  width: 20px;
  height: 20px;
  background-color: var(--yellow);
  border-radius: 100%;
  /* position: absolute; */
  /* right: 30px;
  top: 22px; */
}

.chat-agent-name {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 10px;
  width: -webkit-fill-available;
}

.chat-agent-name h3 {
  color: var(--white);
  font-size: 18px;
  line-height: 22px;
  font-family: "jokker-semibold";
  margin: 0;
}

.chat-agent-name p {
  color: var(--white);
  font-size: 18px;
  line-height: 22px;
  font-family: "jokker-regular";
  opacity: 0.5;
  margin: 0;
}

.chat-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--turquoise);
  text-transform: uppercase;
  font-size: 18px;
  line-height: 22px;
  font-family: "jokker-semibold";
  display: flex;
  justify-content: center;
  align-items: center;
}
.position-relative {
  position: relative;
}
.absoltebtn {
  /* position: absolute !important;
  right: 0;
  top: 50%;
  transform: translateY(-50%); */
  white-space: nowrap;
}
.underlinenotification{
  position: relative;
  margin-bottom: 20px !important;
}
.underlinenotification::after{
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: radial-gradient(50% 50% at 50% 50%, var(--header-bg) 0%, rgba(191, 2, 2, 0) 100%);
  bottom: -15px;
}
.labelquestion label{
  padding-left: 10px;
  padding-top: 8px;
  align-items: start !important;
}
.labelquestion label span{
  padding-top: 0;
}
.h100 {
  height: 100%;
}
.yolohimg {
  transform: translateX(-50%);
  position: absolute;
  width: calc(100% - 140px);
  /* width: 95%; */
  left: 50%;
  top: 20px;
}
.bannercss {
  display: flex;
  height: 100%;
  align-items: flex-end;
  gap: 40px;
  justify-content: center;
}
.positionunset {
  position: unset;
}

.quote-tabs {
  border-bottom: 2px solid var(--turquoise);
}

.quote-tabs .MuiTabs-indicator {
  height: 4px;
  background-color: var(--black);
  border-radius: 4px 4px 0px 0px;
}

.quote-tabs .MuiTab-root {
  font-size: 18px;
  line-height: 20px;
  color: var(--black);
  font-family: "jokker-semibold";
  text-transform: capitalize;
}
.logoinvert {
  filter: brightness(0) invert(1);
}

/* .verify-insurance-content .view_button:hover{
  color: black !important;
}
.verify-insurance-policy .view_button:hover{
  background-color: var(--yellow) !important;
} */
/* .mainHeaderScroll.mainHeader{
  transform: translateY(-84px);
}
.thirdHeaderMain.mainHeaderScroll{
  top: 0;
  background-color: var(--header-bg);
}
.thirdHeaderMain.mainHeaderScroll .thirdHeader a{
  color: white;
}
.thirdHeaderMain.mainHeaderScroll .thirdHeader .active{
  background-color: white;
  color: var(--header-bg);
} */

@media only screen and (max-width: 1700px) {
  .chat_container_width {
    width: unset;
    margin-left: unset;
    padding: 0px 30px;
  }

  .mr-30 {
    margin-right: unset;
  }
  .coverages_item {
    padding: 15px 0px;
  }
}

@media only screen and (max-width: 1600px) {
  /* .insurance-text,
  .insurance-text span {
    max-width: 215px;
  } */
}

/* @media only screen and (max-width: 1500px) {
  .insurance-text,
  .insurance-text span {
    max-width: 200px;
  }
} */

@media only screen and (max-width: 1440px) {
  /* .container_width {
    padding: 0px 50px;
    max-width: 1240px;
  } */

  /* .agent_container_width {
    padding: 0px 50px;
    max-width: 1240px;
  } */
}

@media only screen and (max-width: 1440px) {
  .popupBg .illuuuu {
    width: 210px;
  }
  .popupBg .form_section input,
  .popupBg .form_section .MuiOutlinedInput-input {
    padding: 10px !important;
  }
  .form_section label {
    font-size: 14px !important;
    top: -4px !important;
  }
  .model-contain-main-responsive-upload {
    max-height: 80vh !important;
  }
  .how_work_section,
  .aboutSection,
  .partner {
    padding: 50px 0 !important;
  }

  /* .quote-list-model .MuiDrawer-paper {
    width: 360px;
  } */
  .popupBg {
    width: 825px;
    height: 500px;
  }
  .popupBg.delete-popup  {
    width: 825px;
    height: unset;
  }
  .hero_main {
    /* padding-top: 150px !important; */
  }
  .popupContain img {
    transform: scale(0.8);
  }
  .illu {
    width: 323px;
  }
  .yolohimg {
    /* top: -130px; */
  }

  .offer_card {
    padding: 25px !important;
  }
  .offer_card svg {
    left: 10px !important;
  }
  .join_section h1 {
    margin-bottom: 50px !important;
  }
  .popupContain {
    gap: 15px;
    padding-top: 50px;
  }
  /* .formContainMain {
    padding: 25px !important;
  } */
  .h-100 {
    height: 100%;
  }
  /* .overflow {
    overflow-y: auto;
    height: 100%;
  } */
  .overflow::-webkit-scrollbar {
    display: none;
  }
  .sideBarTopMenu span,
  .sideBarBottomMenu li {
    padding: 20px !important;
  }
}

@media only screen and (max-width: 1799.98px) {
  .chat-header .userImg {
    height: 35px;
    width: 35px;
    font-size: 18px;
  }
  .chat-icon-info h4,
  .quotes_slider_header {
    font-size: 18px;
  }
  .chat-header {
    padding: 9px 40px;
  }
  .my-chat-title {
    font-size: 18px;
    padding: 18px 0px 18px 10px;
  }
  .quotes_slider_header {
    height: 58px;
  }
  .quote-tabs .MuiTab-root {
    font-size: 16px;
    padding: 8px 16px;
  }
  .chat-agent-name p,
  .chat-agent-name h3,
  .chat-type input,
  .receiver-message {
    font-size: 16px;
  }
  .no-quotes-found-chat {
    font-size: 14px;
  }
  .my-chat-content {
    padding: 16px 20px;
  }
  .chat-agent-name {
    gap: 5px;
  }
  .chat-type {
    padding: 10px 20px !important;
  }
  .chat-type input {
    padding: 10px 15px;
  }
  .chat-type input::placeholder {
    font-size: 16px;
  }
  .imgArea img {
    width: 18px;
    height: 18px;
  }
  .send-btn {
    height: 35px;
    width: 35px;
  }
  .send-btn img {
    height: 16px;
    width: 16px;
  }
  .chat-body-inner {
    height: calc(100vh - 345px);
  }
  .my-chat-list {
    padding-top: 15px;
  }
  .agent_accordion .MuiAccordionSummary-root {
    padding: 15px !important;
  }
  .previewSteps {
    padding: 11px;
  }
  .preview-main-header.active-step::before {
    bottom: -9px;
    height: 2px;
  }
  .my-chats-section {
    padding: 0 16px;
  }
  .quote_slider_inner p,
  .quote-card-prise,
  .chat-quotes-button {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .receiver p {
    font-size: 16px;
  }
  .preview-count {
    height: 30px;
    width: 30px;
    font-size: 14px;
  }
  .lead-Steps {
    padding: 10px;
    gap: 10px;
  }
  /* .active-step::before {
    height: 4px;
    bottom: -10px;
  } */
  .preview-main-header {
    gap: 10px;
  }
  .preview-main-header h1 {
    font-size: 15px;
  }
  .preview-main-header svg {
    height: 31px;
    width: 32px;
  }
}
@media only screen and (max-width: 1499px) {
  .main_modal {
    max-width: 475px !important;
  }

  .main_modal {
    transform: scale(0.9) translate(-55%, -55%);
  }
  .model-contain-main-responsive-upload {
    max-height: 80vh !important;
    padding: 25px 35px !important;
  }
  .model-contain-main-responsive button {
    height: 45px !important;
    border: none !important;
    font-size: 18px !important;
  }
  .existingInsuranceLoginBtn {
    width: 300px;
  }
  .dividerMainPopup {
    margin: 25px 0px;
  }
  .dividerMainPopup div {
    font-size: 16px;
  }
  .p-40 {
    padding: 25px;
}
.FilesDragAndDrop__area{
  padding: 0px 15px !important;
}

.FilesDragAndDrop__area svg {
  height: 20px;
  width: 20px;
}
.FilesDragAndDrop__area {
  padding: 0px 10px !important;
  min-height: 60px !important;
}
.modal_logo {
  width: 133px;
  height: 25px;
  margin: 0 auto;
}
.modal_bg{
  border-radius: 10px;
}
.noflex .MuiGrid-spacing-xs-6 {
  display: block !important;
}
.noflex .MuiGrid-spacing-xs-6 .MuiGrid-grid-sm-6 {
  width: 100% !important;
  max-width: 100% !important;
}
.scaledown + .MuiPopover-root .MuiPaper-rounded{
  transform: scale(0.9) !important;
}
.modal_button {
  padding: 10px;
  width: 100%;
}
}
@media only screen and (max-width: 1299px) {
  .insurance-field-error {
    top: 46px !important;
  }
  .contentTitleBox h3 {
    font-size: 16px !important;
  }
  .saveAsDraftBtn {
    width: 151px;
    height: 39px;
  }
  .nextbtnresponsive {
    height: 39px !important;
  }
  .quote-list-model .MuiDrawer-paper {
    width: 340px;
  }
  .partner_box {
    justify-content: center !important;
  }

  .handwidth {
    width: 30%;
  }
  /* .container_width {
    padding: 0px 100px;
    max-width: 1024px;
  }
  .yolohimg {
    width: calc(100% - 200px);
  } */
  .illu {
    width: 323px;
  }
  .p-40 {
    padding: 25px;
  }
  .preview-main-header.active-step::before {
    bottom: -13px;
  }
  .mainSideBar,
  .image_col_bg {
    padding: 25px;
  }
  .sideBarUser .profileUserName {
    font-size: 24px;
  }
  .sideBarUser .sideBarUserIcon {
    height: 50px;
    width: 50px;
  }
  /* .sideBarTopMenu,
  .sideBarBottomMenu {
    margin-top: 18px;
  padding-top: 18px;
  } */
  .customer-information-out-line .insurance-field-section {
    padding:24px !important;
  }
  .inner-form-heading,
  .insurance-form-label,
  .insurance-label {
    font-size: 14px;
  }

  .insurance-text,
  .paddingforce input,.paddingforce div.MuiOutlinedInput-input,.main-form-field input,.main-form-field .MuiFormControl-fullWidth .MuiSelect-outlined,.customer-information-input  .MuiFormControl-fullWidth .MuiSelect-outlined  {
    padding: 11.5px 10px !important;
    font-size: 14px !important;
  }
  .address_autocomplete .MuiAutocomplete-inputRoot  {
    padding: 0 !important;
  }
  .gap-row-30 {
    row-gap: 15px;
  }
  .chat-header-right button {
    font-size: 16px !important;
    padding: 4px 10px !important;
  }
  .preview-right-btn {
    padding: 12px 0;
  }
  .notFoundContain img {
    width: 73%;
    margin: 20px auto;
  }

  
 
 
  .modal_devider {
    margin: 16px auto 16px auto;
  }

  .titleWidth .image_col_bg_step_text {
    font-size: 16px;
    max-width: 180px !important;
  }

  .res-modal-title {
    font-size: 28px;
  }
  .quotes_slider_header {
    padding: 0;
    font-size: 16px;
  }
  .my-chat-title,
  .vaultPinTitle,
  .chat-avatar,
  .chat-icon-info h4,
  .quotes_slider_header,
  .option_card_header p {
    font-size: 16px;
  }
 
  .chat-avatar {
    height: 25px;
    width: 25px;
  }
  .hero_text {
    padding-bottom: 125px !important;
  }
  
  .preview-main-header h1 {
    font-size: 14px;
  }

  .chat-agent-name p,
  .chat-agent-name h3,
  .chat-type input,
  .receiver-message,
  .receiver p {
    font-size: 14px;
  }
  .receiver-message {
    padding: 15px 25px;
  }
  .quote-card-is-accepted {
    font-size: 14px;
    padding: 5px 10px;
  }
  .css-o4b71y-MuiAccordionSummary-content {
    margin: 8px 0 !important;
  }
  .chat-header .userImg {
    height: 30px;
    width: 30px;
    font-size: 14px;
  }
 

  .vaultPinDescription {
    font-size: 14px;
  }
  .pin-section {
    padding: 30px 0px 30px 0px;
  }
  .vaultPinContain {
    margin-top: 25px;
    padding: 25px 0;
  }
  .forgetVaultPin {
    font-size: 14px;
    margin-top: 20px;
  }
  .view_button,
  .policy-tabs {
    font-size: 16px !important;
  }
  .step_img {
    height: 240px;
  }
  .chat-header-right button,
  .breadcrumb-item {
    font-size: 14px !important;
  }
  .insurance-subheading {
    padding-bottom: 20px;
  }
  .secondUserIcon {
    height: 35px;
    width: 35px;
    font-size: 16px;
  }
  .secondHeaderItem svg {
    font-size: 24px;
  }
  .secondHeaderItem {
    gap: 15px;
  }
  .option_card_header input {
    min-height: 20px;
    min-width: 20px;
    height: 20px;
    width: 20px;
  }
  .overview_title {
    font-size: 22px;
  }
  .MuiBadge-badge {
    font-size: 10px !important;
  }
  .insurance-details {
    margin-bottom: 20px;
    padding: 15px;
  }
  .verify-model-header p {
    font-size: 18px;
  }
  .insurance-title,
  .insurance-title-text,
  .preview-sub-header p {
    font-size: 16px;
    line-height: 20px;
  }
  .h-unset + .MuiInputBase-root .MuiOutlinedInput-input {
    height: 20px !important;
  }
 
  .model-contain .css-1h77wgb {
    display: block !important;
  }
  .css-1h77wgb .MuiGrid-grid-sm-6 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .preview-main-header {
    padding: 8px;
    gap: 10px;
  }
  .preview-sub-header {
    padding: 20px 0px 19px 75px;
    gap: 10px;
  }
  .insurance-subheading {
    font-size: 18px;
  }
  .insurance-label {
    font-size: 14px !important;
  }
  .quote-name,
  .quote_slider_inner p {
    font-size: 16px !important;
  }
  .quote_slider_inner p,
  .quote-card-prise,
  .chat-quotes-button {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .bindableTermsAndCondition label {
    font-size: 14px;
  }
  .quote_slider_contain {
    margin-bottom: 15px;
  }
  .MuiCollapse-wrapper .gap-20 {
    gap: 10px;
  }

  .overview_insurance_card {
    padding: 15px;
  }
  .quote_btn {
    height: 48px !important;
    font-size: 16px !important;
  }

  .py-30 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .info-icon img {
    height: 13px;
  }
  .px-30 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .bindableTermsAndCondition {
    font-size: 12px !important;
  }
  .previewSteps {
    padding: 11px;
  }
  .preview_edit_btn {
    padding: 7px 28px !important;
  }
  .modal_devider p {
    margin: 10px 0;
  }
 
}
@media only screen and (max-width: 1200px) {
  /* .chat-agent-name h3 {
    display: none !important;
  } */
  /* .active-chat-dot{
    display: none !important;
  } */
  /* .my-chat-content{
    gap: 0px;
    justify-content: center;
  } */
  /* .customer-information-input {
    width: 47%;
  } */

  .teams-contain {
    flex-direction: column;
    align-items: end;
    gap: 20px;
  }

  /* .container_width {
    max-width: 1024px;
    padding: 0px 50px;
  }

  .agent_container_width {
    max-width: 1150px;
    padding: 0px 50px;
  } */

  .QuoteListToggal {
    display: block !important;
  }

  .pr-75,
  .pl-75 {
    padding: 0 !important;
  }

  .thirdHeader {
    gap: 30px;
  }

  .card-side-bar {
    display: none;
  }

  .agent-chat .chat-board {
    width: 100%;
  }
  .textcentermd {
    text-align: center;
  }
  .widthauto {
    width: fit-content;
  }
  .justifymd {
    align-items: center !important;
  }
  .hero_image_main {
    width: 60%;
  }
}
@media only screen and (max-width: 1199px) {
  .insurance-label {
    /* max-width: 40%;
    min-width: 40%; */
  }

  .insurance-info-main {
    /* justify-content: space-between; */
    width: -webkit-fill-available;
    width: -moz-available;
  }
}
/* @media only screen and (max-width: 1199px) {
  .insurance-label {
    max-width: 250px;
    min-width: 250px;
  }
} */

/* @media only screen and (max-width: 1080px) {
  .insurance-text,
  .insurance-text span {
    max-width: 190px;
  }
} */
@media only screen and (max-width: 1055px) {
  .side-bar {
    display: none;
  }
  .contact_section_main::before {
    width: 100% !important;
  }
  .activity-dashboard {
    margin-left: 0;
  }

  .grid-box {
    display: block;
  }

  .profile-input-section {
    padding: 30px;
  }

  .profile-input-main {
    justify-content: space-between;
  }

  .thirdHeader {
    gap: 20px;
  }

  .headerLink {
    margin-left: 0px;
    gap: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .loginBtn.loginBtnHeader.responsiveBtnHeader {
    display: none !important;
  }
  .yolohimg {
    position: absolute;
    /* width: calc(100% - 60px); */
    /* top: -125px; */
  }
  /* .container_width {
    max-width: 768px;
    padding: 0px 30px;
  } */

  .agent_container_width {
    /* max-width: 864px; */
    padding: 0px 30px;
  }

  /* .chat_container_width {
    max-width: 804px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  } */

  .thirdHeader {
    gap: 7px;
  }

  .leander_user_model {
    width: 85% !important;
  }

  .main_modal {
    width: auto;
  }

  .model-contain-main-upload {
    width: 90% !important;
  }

  .sidebarToggal {
    display: flex !important;
    align-items: center;
    padding: 0;
    margin: 0;
    min-width: unset !important;
  }

  .headerLink {
    display: none;
  }

  .responsive-header {
    display: block;
  }
  .image_col_bg {
    padding: 30px;
  }
}

@media only screen and (max-width: 992px) {
  /* .layout {
    margin-top: 60px;
  } */
  .quote-list-model .MuiDrawer-paper {
    height: calc(100vh - 185px);
  }

  .chat-body-inner {
    height: calc(100vh - 389px);
  }

  /* .my-chat-list {
    height: calc(100vh - 292px);
  } */

  .help-chat-body-inner {
    height: calc(100vh - 254px);
  }

  .heroSection .slick-dots {
    margin: auto !important;
    text-align: center !important;
    width: 100% !important;
    bottom: 10px !important;
  }

  .document-footer {
    flex-direction: column;
    gap: 10px;
  }

  .heroImage img {
    width: 300px;
    margin: auto;
  }

  .insurance-header {
    top: 63px;
  }

  .setting-img {
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .setting-text {
    font-size: 22px;
  }

  .insuranceInfo {
    padding-left: 0px;
  }

  .layout {
    min-height: calc(100vh - 200px);
  }

  .about_card_main {
    width: -webkit-fill-available;
    width: -moz-available;
  }
}

@media only screen and (max-width: 990px) {
  /* .insurance-text,
  .insurance-text span {
    max-width: 150px;
    min-width: 150px;
  } */
  /* .insurance-label {
    max-width: 150px;
    min-width: 150px;
  } */
}

/* @media only screen and (max-width: 899px) {
  .insurance-text,
  .insurance-text span {
    max-width: unset;
    min-width: unset;
  }
} */
@media only screen and (max-width: 899.98px) {
  .hero_main {
    height: 100% !important;
  }
  .hero_text {
    padding-bottom: 0 !important;
    padding-top: 200px !important;
  }
  .footer_details {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .footer_details div,
  .footer_details > p {
    width: 100%;
    justify-content: center;
  }
  .team_sub_desc .p_tag {
    max-width: unset;
  }

  .yolohimg {
    width: 95%;
  }
}

@media only screen and (max-width: 870px) {
  .form-radio-savan {
    width: 100% !important;
  }
}

@media only screen and (max-height: 600px) {
  .create-acc-res {
    height: 295px;
    overflow: auto;
  }
}

@media only screen and (max-width: 767px) {
  /* .chat_btn {
    display: none !important;
  } */
  .coverages_item p {
    padding: 0px 15px;
  }

  .chat_btn_icon {
    display: block;
  }

  .edit-button {
    display: none;
  }

  .edit-button-icon {
    display: flex;
  }

  .dashboard-card {
    width: 100%;
  }

  .add-res {
    flex-direction: column;
    gap: 0px;
  }

  .delete-popup {
    width: unset;
  }

  .notification_left {
    flex-direction: column;
    align-items: baseline;
  }

  .notification_card {
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }

  .survey-type-img {
    width: 110px;
  }

  /* .container_width {
    max-width: 450px;
    padding: 0px 30px;
  } */

  .agent_container_width {
    /* max-width: 680px; */
    padding: 0px 20px;
  }

  .customer-information-input {
    width: 100%;
  }

  .step_line {
    width: 847px;
    top: 54%;
  }

  .applicant-stap {
    background-color: var(--header-bg);
  }

  .applicant-stap {
    flex-direction: row;
    width: unset;
  }

  .main-step {
    justify-content: unset;
    gap: 40px;
  }

  .quotes-tab,
  .preview-tab {
    width: 150px;
  }

  .common-shadow {
    padding: 20px 20px;
  }

  .insurance-heading {
    margin-bottom: 14px;
  }

  .px-40 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .py-40 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .insurance-info-main {
    justify-content: space-between;
  }

  .heroInfo {
    height: unset;
    justify-content: center;
  }

  .aboutSectionInner {
    justify-content: center;
  }

  .step_img {
    display: none;
  }

  /* .preview-main-header-section-inner {
    flex-direction: column;
    row-gap: 20px;
  } */

  .chat-body-inner {
    height: calc(100vh - 440px);
  }
}

@media only screen and (max-width: 600px) {
  /* .preview-right-btn {
    flex-direction: column;
  } */

  .agent-details {
    flex-direction: column;
    row-gap: 20px;
  }

  .heroImage {
    margin: auto;
  }

  .form-section {
    padding: 20px;
  }

  .vaultPinBtn {
    width: auto;
  }

  .insurance-header {
    height: auto;
    padding: 10px 0;
  }

  .heroSection .slick-dots {
    bottom: 100px !important;
  }

  .productMain {
    justify-content: center;
  }

  .productImg {
    width: 200px !important;
    height: auto;
  }

  .quotes-tab,
  .preview-tab {
    width: 100px;
  }

  .edit-button {
    width: 100px;
  }

  .heroText h1 {
    font-size: 25px;
    line-height: 30px;
  }

  .setting-text {
    font-size: 20px;
  }
  .hero_text {
    padding-top: 125px !important;
  }
  .customer-information-out-line {
    padding: 0px;
  }

  .insurance {
    padding: 20px 0;
  }

  .receiver-message {
    padding: 10px 20px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0px;
  }

  .sender-message {
    padding: 10px 20px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0px;
  }

  .insurance-text,
  .insurance-text span,
  .insurance-label {
    max-width: unset;
    min-width: unset;
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .insurance-ul-list {
    max-width: unset;
    min-width: unset;
  }

  .insurance-info-main {
    flex-direction: column;
    gap: 0;
  }

  .profile-input-section {
    padding: 20px;
  }
}

.moreIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100px;
  display: none !important;
}

.responsive-my-chats-section .my-chats-section {
  display: block !important;
}

@media only screen and (max-width: 1535px) {
  .chat-agent-name p {
    display: none !important;
  }

  .my-chat-content {
    padding: 12px 15px !important;
  }

  /* .my-chats-section {
    display: none !important;
  }
  .moreIcon {

    display: block !important;
  }
  .quotes-list-close{
    display: block !important;
  }
  
  .my-chat-list {
    max-height: calc(100vh - 110px) !important;
  } */
}

@media only screen and (max-width: 900px) {
  .my-chats-section {
    display: none !important;
    width: 370px !important;
  }

  .quote-list-model .MuiDrawer-paper {
    height: 100%;
    bottom: unset;
    right: 0;
    border: unset;
    top: 0;
  }

  /* .my-chats-section{

  } */
  .my-chat-content {
    gap: 15px;
    justify-content: unset;
  }

  .chat-agent-name p,
  .chat-agent-name h3 {
    display: block !important;
  }

  .active-chat-dot {
    display: block !important;
  }

  .quotes-list-close {
    display: block !important;
  }

  .moreIcon {
    display: block !important;
  }

  .my-chat-list {
    max-height: calc(100vh - 110px) !important;
  }
}

@media only screen and (max-width: 484px) {
  .survey-type-img {
    width: 90px;
    height: 80px;
  }

  .moreIcon {
    right: 75px;
  }
}

@media only screen and (max-height: 425px) {
  .main_modal_add_id {
    width: max-content;
  }
}

@media only screen and (max-width: 550px) {
  .insurance-details {
    flex-direction: column;
  }

  .insurance-type,
  .insurance-status {
    text-align: center;
  }

  .separate-line {
    width: -webkit-fill-available !important;
    height: 1px;
    margin: 10px 0px;
  }
}

@media only screen and (max-width: 425px) {
  /* .container_width {
    max-width: 375px;
    padding: 0px 30px;
  } */

  .agent_container_width {
    /* max-width: 385px; */
    padding: 0px 20px;
  }

  .chat-header-right button {
    padding: 6px 10px !important;
  }

  .agent-connect-btn {
    flex-direction: column;
  }

  .otpInput {
    width: 50px;
  }

  .survey-type-img {
    width: 50px;
    height: 50px;
  }
}

/*********************************************************************************************************************************************************/
/*********************************************** Need to remove this patch and add grid in all input field ***********************************************/
/*********************************************************************************************************************************************************/

@media only screen and (min-width: 1921px) {
  .multiEntryCard .customer-information-input{
    width: 31.9% !important;
  }
  .customer-information-input {
    width: 32%;
    position: relative;
  }
}
@media only screen and (min-width: 1534px) {
  /* .multiEntryCard .customer-information-input{
    width: 32.4% !important;
  } */
  /* .customer-information-input {
    width: 31%;
    position: relative;
  } */
}
@media only screen and (max-width: 1760.98px) {
  .customer-information-input {
    width: 32%;
    position: relative;
  }
}
@media only screen and (max-width: 1677.98px) {
  .customer-information-input {
    width: 31.9%;
    position: relative;
  }
}
@media only screen and (max-width: 1534.98px) {
  .customer-information-input {
    width: 31.8%;
    position: relative;
  }
}
@media only screen and (max-width: 1444.98px) {
  .customer-information-input {
    width: 31.7%;
    position: relative;
  }
}
@media only screen and (max-width: 1363.98px) {
  .customer-information-input {
    width: 31.6%;
    position: relative;
  }
}
@media only screen and (max-width: 1281.98px) {
  .customer-information-input {
    width: 31.5%;
    position: relative;
  }
}
@media only screen and (max-width: 1218.98px) {
  .customer-information-input {
    width: 31.4%;
    position: relative;
  }
}
@media only screen and (max-width: 1162.98px) {
  .customer-information-input {
    width: 31.3%;
    position: relative;
  }
}
@media only screen and (max-width: 1111.98px) {
  .customer-information-input {
    width: 31.2%;
    position: relative;
  }
}
@media only screen and (max-width: 1065.98px) {
  .customer-information-input {
    width: 31.1%;
    position: relative;
  }
}
@media only screen and (max-width: 1035.98px) {
  .customer-information-input {
    width: 31%;
    position: relative;
  }
}
@media only screen and (max-width: 984.98px) {
  .customer-information-input {
    width: 30.9%;
    position: relative;
  }
}
@media only screen and (max-width: 949.98px) {
  .customer-information-input {
    width: 30.8%;
    position: relative;
  }
}
@media only screen and (max-width: 1681.98px) {
  .multiEntryCard .customer-information-input{
    width: 31.8% !important;
  }
}
@media only screen and (max-width: 1486.98px) {
  .multiEntryCard .customer-information-input{
    width: 31.7% !important;
  }
}
@media only screen and (max-width: 1405.98px) {
  .multiEntryCard .customer-information-input{
    width: 31.6% !important;
  }
}
@media only screen and (max-width: 1335.98px) {
  .multiEntryCard .customer-information-input{
    width: 31.5% !important;
  }
}
@media only screen and (max-width: 1204.98px) {
  .multiEntryCard .customer-information-input{
    width: 31.3% !important;
  }
}
@media only screen and (max-width: 1153.98px) {
  .multiEntryCard .customer-information-input{
    width: 31.2% !important;
  }
}
@media only screen and (max-width: 1107.98px) {
  .multiEntryCard .customer-information-input{
    width: 31.1% !important;
  }
}
@media only screen and (max-width: 1065.98px) {
  .multiEntryCard .customer-information-input{
    width: 31% !important;
  }
}
@media only screen and (max-width: 1026.98px) {
  .multiEntryCard .customer-information-input{
    width: 30.9% !important;
  }
}
@media only screen and (max-width: 991.98px) {
  .multiEntryCard .customer-information-input{
    width: 30.8% !important;
  }
}
@media only screen and (max-width: 1610.98px) {
  /* .multiEntryCard .customer-information-input{
    width: 32.3% !important;
  } */
}
@media only screen and (max-width: 1471.98px) {
  /* .multiEntryCard .customer-information-input{
    width: 32.2% !important;
  } */
}
@media only screen and (max-width: 1358.98px) {
  /* .multiEntryCard .customer-information-input{
    width: 32.1% !important;
  } */
}
@media only screen and (max-width: 1250.98px) {
  /* .multiEntryCard .customer-information-input{
    width: 32% !important;
  } */
}
@media only screen and (max-width: 1169.98px) {
  /* .multiEntryCard .customer-information-input{
    width: 31.9% !important;
  } */
}
@media only screen and (max-width: 1099.98px) {
  /* .multiEntryCard .customer-information-input{
    width: 31.8% !important;
  } */
}
@media only screen and (max-width: 1038.98px) {
  /* .multiEntryCard .customer-information-input{
    width: 31.7% !important;
  } */
}
@media only screen and (max-width: 986.98px) {
  /* .multiEntryCard .customer-information-input{
    width: 31.6% !important;
  } */
}
